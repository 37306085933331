import React from "react"

const Logo = ({className}: { className?: string }) => {
  return <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M256 0C397.6 0 512 114.4 512 256C512 397.6 397.6 512 256 512C114.4 512 0 397.6 0 256C0 114.4 114.4 0 256 0Z" fill="#FCD34D"/>
    <path d="M360 112H336H200H160C142.4 112 128 124.6 128 140V392L232 343L336 392V154H360C373.6 154 384 144.2 384 133C384 121.1 373.6 112 360 112Z" fill="#EA580C"/>
    <path d="M384 224H336V131.765C336 120.565 346.4 112 360 112C373.6 112 384 120.565 384 131.765V224Z" fill="#C2410C"/>
  </svg>
}

export default Logo