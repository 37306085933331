import produce from "immer"
import * as R from "ramda"
import {generateKeyBetween} from "fractional-indexing"
import Store, {Filters as FiltersType} from "../store/Store"
import Bookmark from "../store/Bookmark"
import useStore from "../store/useStore"
import React from "react"

export interface ClearFilters {
  startTransition: React.TransitionStartFunction
  setNonBlockingFilters: React.Dispatch<React.SetStateAction<FiltersType>>
}

const clearFilters = ({startTransition, setNonBlockingFilters}: ClearFilters) => {
  setNonBlockingFilters(produce((filters: FiltersType) => {
    filters.title = ''
    filters.tagIds = new Set()
  }))
  startTransition(() => {
    useStore.setState(produce((store: Store) => {
      store.ui.filters = {
        title: '',
        tagIds: new Set()
      }
      store.ui.unfilteredIds = new Set()
    }))
  })
}

export default clearFilters