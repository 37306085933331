import capitalize from "lodash.capitalize"
import createUuid from "./createUuid"
import Bookmark from "../store/Bookmark"
import Tag from "../store/Tag"
import {Entites} from "../store/Store"

export interface BookmarkPartial extends Omit<Bookmark, 'position'> {
  position?: string
}

export interface TagPartial extends Omit<Tag, 'position'> {
  position?: string
}

export interface ChromeExport extends Pick<Entites, "bookmarkTag"> {
  bookmarks: Record<string, BookmarkPartial>
  tags: Record<string, TagPartial>
  tagsByName: Record<string, TagPartial>
  bookmarksByUrl: Record<string, BookmarkPartial>
}

const fromChromeHtmlToJson = (text: string) => {

  var el = document.createElement('html')
  el.innerHTML = text

  // @ts-ignore
  const root = el.querySelector('dl')

  const result: ChromeExport = {bookmarks: {}, tags: {}, bookmarkTag: {}, tagsByName: {}, bookmarksByUrl: {}}

  root && processEntry(root, result)

  return result

}

const processEntry = (node: Element, result: ChromeExport, tagIds: string[] = []) => {
  // @ts-ignore
  for (const entry of node.children) {
    // @ts-ignore
    const isBookmark = entry.tagName === 'DT' && entry.children[0].tagName === 'A'
    // @ts-ignore
    const isFolder = entry.tagName === 'DT' && entry.children[0].tagName === 'H3'
    if (isBookmark) {
      const url = entry.children[0].href
      const existingBookmark = result.bookmarksByUrl[url]
      let bookmarkId;
      if (!existingBookmark) {
        const bookmark = createBookmark(entry)
        bookmarkId = bookmark.id
        result.bookmarks[bookmarkId] = bookmark
        result.bookmarksByUrl[url] = bookmark
      } else {
        bookmarkId = existingBookmark.id
      }
      for (const tagId of tagIds) {
        const bookmarksTagsId = bookmarkId + '_' + tagId
        result.bookmarkTag[bookmarksTagsId] = {
          id: bookmarksTagsId,
          bookmarkId,
          tagId
        }
      }
    } else if (isFolder) {
      const title = capitalize(entry.children[0].textContent)
      const isRootFolder = tagIds.length === 0 && title === 'Bookmarks bar'
      if (!isRootFolder) {
        const existingTag = result.tagsByName[title]
        let id
        if (!existingTag) {
          id = createUuid()
          const tag = {
            id,
            title,
          }
          result.tags[id] = tag
          result.tagsByName[title] = tag
        } else {
          id = existingTag.id
        }
        tagIds.push(id)
      }
      processEntry(entry.querySelector('dl'), result, tagIds)
      tagIds.pop()
    }
  }
}

const createBookmark = (entry: any) => {
  const bookmarkId = createUuid()
  return {
    id: bookmarkId,
    title: entry.children[0].textContent,
    url: entry.children[0].href
  }
}

export default fromChromeHtmlToJson