import Store from "../store/Store"
import * as R from "ramda"
import {generateKeyBetween} from "fractional-indexing"
import useStore from "../store/useStore"

const deleteTag = ({id}: { id: string }) => (entities: Store["entities"]) => {
  const bookmarksTagsIndex = entities.bookmarkTag
  const bookmarksTagsArray = R.values(bookmarksTagsIndex)
  const btsToDelete = bookmarksTagsArray.filter((bt) => bt.tagId === id)
  const transaction = []
  for (const btToDelete of btsToDelete) {
    transaction.push({
      method: 'delete' as const,
      entity: 'bookmarkTag' as const,
      data: {
        id: btToDelete.id
      }
    })
  }
  transaction.push({
    method: 'delete' as const,
    entity: 'tag' as const,
    data: {
      id
    }
  })
  return transaction
}

export default deleteTag