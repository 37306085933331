import Button from "../../components/ui-kit/Button"
import React from "react"
import useModal from "../../hooks/useBoolean"
import AddTagsModal from "./AddTagsModal"

interface AddTagsButton {
  selectedBookmarkIds: Set<string>
}

const AddTagsButton = ({selectedBookmarkIds}: AddTagsButton) => {

  const [showEditBookmarksModal, onOpenEditBookmarksModal, onCloseEditBookmarksModal] = useModal()

  return <>
    {showEditBookmarksModal && <AddTagsModal selectedBookmarkIds={selectedBookmarkIds} onClose={onCloseEditBookmarksModal}/>}
    <Button size="large" onClick={onOpenEditBookmarksModal}>Add tags</Button>
  </>
}

export default AddTagsButton