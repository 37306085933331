import {ForwardedRef, HTMLAttributes} from "react"
import React from "react"
import {cx, css} from "@emotion/css"

export interface TileProps extends HTMLAttributes<HTMLDivElement> {
}

const Tile = ({children, className, ...rest}: TileProps, ref: ForwardedRef<HTMLDivElement>) => {
  return <div
    ref={ref}
    className={cx(
      css`border: 1px solid var(--coolGrey3); border-radius: 4px; background: white;`,
      className
    )}
    {...rest}
  >
    {children}
  </div>
}

export default React.forwardRef(Tile)