import useStore from "../../store/useStore"
import React, {Dispatch, SetStateAction, useRef} from "react"
import Bookmark from "./Bookmark"
import areFiltersEmpty from "../../utils/areFiltersEmpty"
import useEvent from "../../hooks/useEvent"
import {Filters as FiltersType} from "../../store/Store"
import {useHistory} from "react-router-dom"
import {css, cx} from "@emotion/css"
import {columns, gap1, p3, rows} from "../../styles/styleUtils"
import AddBookmarkButton from "./AddBookmarkButton"
import BookmarksNotFound from "./BookmarksNotFound"
import Divider from "../../components/ui-kit/Divider"
import BookmarkType from "../../store/Bookmark"
import throttle from "lodash.throttle";
import {api} from "../../App"
import deleteBookmark from "../../transactionCreators/deleteBookmark"
import DeleteBookmarksButton from "./DeleteBookmarksButton"
import AddTagsButton from "./AddTagsButton"
import DeleteTagsButton from "./DeleteTagsButton"
import OpenBookmarksButton from "./OpenBookmarksButton"

const bookmarkPath = '/bookmark'

export interface BookmarksProps {
  bookmarksToRender: BookmarkType[]
  filteredBookmarks: BookmarkType[]
  setNonBlockingFilters: Dispatch<React.SetStateAction<FiltersType>>
  onFullyScrolled: () => void
  selectedBookmarkIds: Set<string>
  setSelectedBookmarkIds: Dispatch<SetStateAction<Set<string>>>
}

const Bookmarks = ({bookmarksToRender, filteredBookmarks, setNonBlockingFilters, onFullyScrolled, selectedBookmarkIds, setSelectedBookmarkIds}: BookmarksProps) => {

  const filters = useStore((s) => s.ui.filters)
  const emptyFilters = areFiltersEmpty(filters)

  let history = useHistory();

  const onEdit = useEvent((id: string) => {
    history.push(bookmarkPath + '/' + id);
  })

  const throttledOnScrollRef = useRef<(e: React.UIEvent<HTMLElement>) => void>()
  if (!throttledOnScrollRef.current) {
    throttledOnScrollRef.current = throttle((e) => {
      const scrollableArea = e.target
      const threshold = 300;
      if (scrollableArea.scrollTop + scrollableArea.clientHeight >= scrollableArea.scrollHeight - threshold) {
        onFullyScrolled()
      }
    }, 300)
  }

  const onSelectAllBookmarks = useEvent((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedBookmarkIds(new Set(filteredBookmarks.map((b) => b.id)))
    } else {
      setSelectedBookmarkIds(new Set())
    }
  })

  const onSelectBookmark = useEvent((bookmarkId: string, selected: boolean) => {
    setSelectedBookmarkIds((selectedBookmarkIds) => {
      const newSelectedBookmarkIds = new Set(Array.from(selectedBookmarkIds))
      if (selected) {
        newSelectedBookmarkIds.add(bookmarkId)
      } else {
        newSelectedBookmarkIds.delete(bookmarkId)
      }
      return newSelectedBookmarkIds
    })
  })

  const onDeleteBookmarks = useEvent(async () => {
    for (const bookmarkId of Array.from(selectedBookmarkIds)) {
      api.applyAndDispatch(deleteBookmark({id: bookmarkId}))
      onSelectBookmark(bookmarkId, false)
    }
  })

  const selectedBookmarkLength = selectedBookmarkIds.size

  return <div className={cx(columns, css`height: 100%;`)}>
    <div className={cx(rows, p3, gap1, css`justify-content: space-between; align-items: center; border-bottom: 1px solid var(--coolGrey3);`)}>
      <div className={cx(css`flex: 1;`, rows, gap1, css`text-wrap: nowrap;`)}>
        {filteredBookmarks.length > 0 && <input type="checkbox" checked={selectedBookmarkLength === filteredBookmarks.length} onChange={onSelectAllBookmarks}/>}
        {selectedBookmarkLength > 0 && <>{selectedBookmarkLength} selected</>}
      </div>
      <div className={cx(rows, gap1, css`justify-content: flex-end; flex-wrap: wrap;`)}>
        {selectedBookmarkLength > 0 && <>
          <OpenBookmarksButton selectedBookmarkIds={selectedBookmarkIds}/>
          <DeleteTagsButton selectedBookmarkIds={selectedBookmarkIds}/>
          <AddTagsButton selectedBookmarkIds={selectedBookmarkIds}/>
          <DeleteBookmarksButton selectedBookmarkLength={selectedBookmarkLength} onDeleteBookmarks={onDeleteBookmarks}/>
        </>}
        <AddBookmarkButton/>
      </div>
    </div>
    <div className={css`flex: 1; overflow: auto;`} onScroll={throttledOnScrollRef.current}>
      {bookmarksToRender.length === 0 && !emptyFilters && <BookmarksNotFound
        setNonBlockingFilters={setNonBlockingFilters}
      />}
      {bookmarksToRender.map(({id}, i) => {
        return <div key={id}>
          {i !== 0 && <Divider/>}
          <Bookmark
            id={id}
            onEdit={onEdit}
            prevPosition={bookmarksToRender[i - 1]?.position}
            nextPosition={bookmarksToRender[i + 1]?.position}
            selectedBookmarkIds={selectedBookmarkIds}
            onSelectBookmark={onSelectBookmark}
          />
        </div>
      })}
    </div>
  </div>

}

export default React.memo(Bookmarks)
