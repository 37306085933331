import Store from "../store/Store"
import useStore from "../store/useStore"
import produce from "immer"
import fetcher from "./fetcher"
import createUuid from "../utils/createUuid"
import initialState from "../store/initialState"
import chunkArray from "../utils/chunkArray"
import runAsync from "../utils/runAsync"

const syncBatchesSize = 40
const reduceBatchesSize = 30

type ValueOf<T> = T[keyof T];

interface Action<T extends keyof Store['entities']> {
  method: 'post' | 'put' | 'patch' | 'delete'
  entity: T,
  data: { id: string } & Partial<ValueOf<Store['entities'][T]>>
}

export type TransactionCreator = (e: Store['entities']) => Array<Action<keyof Store['entities']>>

export type Pending = { id: string, actions: Action<keyof Store["entities"]>[], transactionCreator: TransactionCreator }

let sending = false;

const createEditingSession = () => {

  let baseUrl = ""
  let listeners: Function[] = [];
  let pendings: Map<string, Pending> = new Map()
  let version = 0;
  let confirmedState: Store['entities'] = initialState.entities;
  let interval: number | undefined
  let syncingAndRebasing = false
  const dispatchPromiseControllersById: Map<string, { resolve: (a: any) => void, reject: (a: any) => void }> = new Map();

  const fetchConfirmedState = async () => {
    return await fetcher(`${baseUrl}/api/sync`, {
      method: 'GET'
    })
  }

  const indexEntities = (data: any) => {
    let state: Store['entities'] = {...initialState.entities}
    for (const entityName of Object.keys(initialState.entities)) {
      if (data[entityName]) {
        // @ts-ignore
        state[entityName] = data[entityName].reduce((acc, e) => ({...acc, [e.id]: e}), {})
      } else {
        console.warn(`no entity "${entityName}" returned during sync`)
      }
    }
    return state
  }

  const fetchUpdates = async (version: number) => {
    const params: any = {version}
    return await fetcher(`${baseUrl}/api/sync?` + new URLSearchParams(params), {method: 'GET'})
  }

  const doSend = async (version: any, transactions: any) => {
    return await fetcher(`${baseUrl}/api/sync`, {
      method: 'POST',
      body: JSON.stringify({data: {version, transactions}})
    })
  }

  const reduce = (action: Action<keyof Store['entities']>, state: any) => {
    return produce((s) => {
      if (action.method === 'post') {
        s[action.entity][action.data.id] = action.data
      } else if (action.method === 'patch') {
        s[action.entity][action.data.id] = {
          ...s[action.entity][action.data.id],
          ...action.data
        }
      } else if (action.method === 'delete') {
        delete s[action.entity][action.data.id]
      }
    })(state)
  }

  const init = async (props: { baseUrl: string }) => {

    baseUrl = props.baseUrl

    let {data} = await fetchConfirmedState()
    version = data.version;
    confirmedState = indexEntities(data)

    useStore.setState(produce((s) => {
      s.entities = confirmedState
    }));

    interval = window.setInterval(() => {
      !syncingAndRebasing && !sending && syncAndRebaseAll()
    }, 10000)

    //todo make this immediate instead
    interval = window.setInterval(async () => {
      if (!sending) {
        sending = true
        try {
          await send()
        } catch (e) {
          swallowNetworkErrors(e)
        }
        sending = false
      }
    }, 1000)

  }

  const send = async () => {

    try {

      const batches = chunkArray(Array.from(pendings.values()), syncBatchesSize)

      for (const pendingsFromBatches of batches) {

        const transactions = []

        for (const pendingFromBatch of pendingsFromBatches) {
          if (pendingFromBatch.actions.length > 0) {
            transactions.push({id: pendingFromBatch.id, actions: pendingFromBatch.actions})
          } else {
            pendings.delete(pendingFromBatch.id);
          }
        }

        if (transactions.length > 0) {
          await doSend(version, transactions)
        }

        for (const transaction of transactions) {
          for (const action of transaction.actions) {
            confirmedState = reduce(action, confirmedState)
          }
          version = version + 1
          pendings.delete(transaction.id);
          dispatchPromiseControllersById.get(transaction.id)?.resolve(undefined);
          dispatchPromiseControllersById.delete(transaction.id)
        }

        pendings = new Map(pendings)
        emitPendingsChange()

      }

    } catch (e: any) {
      if (e.status === 409) {
        console.log('=====conflict=====')
        await syncAndRebaseAll()
        await send()
      } else {
        //todo********
        //dispatchPromiseControllersById.get(id)?.reject(e);
        //dispatchPromiseControllersById.delete(id)
        throw e
      }
    }

  }

  const apply = (transactionCreator: TransactionCreator) => {

    const entities = useStore.getState().entities
    const actions = transactionCreator(entities)

    const id = createUuid()
    pendings.set(id, {id, actions, transactionCreator})
    pendings = new Map(pendings)
    emitPendingsChange()

    let state = entities
    for (const action of actions) {
      state = reduce(action, state);
    }

    useStore.setState((s) => ({...s, entities: state}))

    return id
  }

  const dispatch = async (id: string) => {
    return new Promise<any>((resolve, reject) => {
      dispatchPromiseControllersById.set(id, {resolve, reject});
    })
  }

  const syncAndRebaseAll = async () => {
    syncingAndRebasing = true
    //todo improve sync protocol and error handling
    try {
      const {data: {version: serverVersion, transactions}} = await fetchUpdates(version)
      for (const transaction of transactions) {
        for (const action of transaction.actions) {
          confirmedState = reduce(action, confirmedState)
        }
      }
      version = serverVersion
      let uiState = confirmedState
      const pendingsArray = Array.from(pendings).map(([_, value]) => value)
      const chunks = chunkArray(pendingsArray, reduceBatchesSize);
      for (const chunk of chunks) {
        await runAsync(() => {
          for (const {id, transactionCreator} of chunk) {
            const rebasedActions = transactionCreator(uiState)
            pendings.set(id, {id, actions: rebasedActions, transactionCreator})
            for (const action of rebasedActions) {
              uiState = reduce(action, uiState)
            }
          }
        })
      }
      useStore.setState((s) => ({...s, entities: uiState}))
    } catch (e) {
      swallowNetworkErrors(e)
    }
    syncingAndRebasing = false
  }

  /*
  - apply actionX to ui state
  - send actionX
  - if rejected, apply remote state, mutate actionX in actionY and retry with actionY
  */
  const applyAndDispatch = async (transactionCreator: TransactionCreator) => {
    const id = apply(transactionCreator)
    await dispatch(id)
  }

  const emitPendingsChange = () => {
    for (let listener of listeners) {
      listener();
    }
  }

  const discard = (id: string) => {
    pendings.delete(id)
    pendings = new Map(pendings)
    emitPendingsChange()
  }

  return {
    init,
    apply,
    dispatch,
    discard,
    applyAndDispatch,
    subscribe(listener: typeof listeners[0]) {
      listeners = [...listeners, listener];
      return () => {
        listeners = listeners.filter(l => l !== listener);
      };
    },
    getSnapshot() {
      return pendings;
    },
    destroy() {
      window.clearInterval(interval)
    }
  }

}

//todo could type the errors in fetcher.ts instead
const isNetworkError = (e: any) => {
  return e.ok === undefined
}

const swallowNetworkErrors = (e: any) => {
  if (isNetworkError(e)) {
    console.warn('Network request failed without status, this is likely a temporary connection error, the request will be retried shortly')
  } else {
    throw e
  }
}

export default createEditingSession;
