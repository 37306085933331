import {css, cx} from "@emotion/css"
import {columns, gap3, p4} from "../../styles/styleUtils"
import SubTitle from "../../components/ui-kit/SubTitle"
import Icon from "../../components/ui-kit/Icon"
import {faMagnifyingGlassMinus} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlassMinus"
import Button from "../../components/ui-kit/Button"
import React, {useTransition} from "react"
import clearFilters from "../../reducers/clearFilters"
import {Filters as FiltersType} from "../../store/Store"


interface BookmarksNotFoundProps {
  setNonBlockingFilters: React.Dispatch<React.SetStateAction<FiltersType>>
}

const BookmarksNotFound = ({setNonBlockingFilters}: BookmarksNotFoundProps) => {

  const [filtering, startTransition] = useTransition()

  return <div className={cx(columns, p4, gap3, css`align-items:center;`)}>
    <SubTitle level="l3">
      <Icon glyph={faMagnifyingGlassMinus} className={css`margin-right:4px;`}/>
      No results for the selected filters
    </SubTitle>
    <Button
      disabled={filtering}
      variant="secondary"
      size="large"
      onClick={() => clearFilters({startTransition, setNonBlockingFilters})}>
      {filtering ? "Clearing filters..." : "Clear filters"}
    </Button>
  </div>
}

export default BookmarksNotFound