import {useEffect} from "react"
import throttle from "lodash.throttle"
import useStore from "../store/useStore"
import produce from "immer"

export const getActiveBreakpoint = () => {
  return window.innerWidth <= 768 ? 'mobile' : 'desktop'
}

const useResponsiveBreakpoints = () => {
  useEffect(() => {
    const onResize = throttle(() => {
      useStore.setState(produce((store) => {
        store.ui.activeBreakpoint = getActiveBreakpoint()
      }))
    }, 300)
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [])
}

export default useResponsiveBreakpoints