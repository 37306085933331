import Store from "../store/Store"
import * as R from "ramda"
import {generateKeyBetween} from "fractional-indexing"

const editTagPosition = ({id, position}: { id: string, position: string }) => (entities: Store["entities"]) => {
  let transaction = []
  const bookmarkIndex = entities.bookmark
  const bookmarks = R.values(bookmarkIndex)
  const itemWithSamePosition = bookmarks.find((t) => t.position === position)
  if (itemWithSamePosition) {
    const sortedBookmarks = R.sortBy(R.prop('position'), bookmarks)
    const index = sortedBookmarks.findIndex((t) => t === itemWithSamePosition)
    const nextPosition = sortedBookmarks[index + 1]?.position
    position = generateKeyBetween(position || null, nextPosition || null)
  }
  if (bookmarkIndex[id]) {
    transaction.push({
      method: 'patch' as const,
      entity: 'bookmark' as const,
      data: {
        id,
        position
      }
    })
  }
  return transaction
}

export default editTagPosition