import {cx, css} from "@emotion/css"
import {AnchorHTMLAttributes} from "react"

export interface AProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
}

const A = ({className, ...rest}: AProps) => {
  return <a
    className={cx(
      css(`
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: var(--blue7);
      `),
      className
    )}
    {...rest}
  />

}

export default A