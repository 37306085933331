export type Validator = (value: string) => string | undefined

export const notEmpty = (value: string) => {
  return value.trim().length === 0 ? "Cannot be empty" : undefined
}

export const isUrl = (value: string) => {
  return !isValidHttpUrl(value) ? "Not a valid http/s url" : undefined
}

export const isValidHttpUrl = (string: string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export const isValid = (validationResult: Array<string | undefined>) => {
  return validationResult.every((v) => v === undefined)
}

//export const validate = <T extends string | number | symbol>(specs: Record<T, Validator[]>, values: Record<T, string>) => {
//  return Object.keys(specs).reduce(
//    (acc, key) => {
//      const validationResult = specs[key as T].map(validator => validator(values[key as T]))
//      return [...acc, validationResult]
//    },
//    [] as Array<Array<string | undefined>>
//  )
//}

//export const isFormValid = <T extends string | number | symbol>(specs: Record<T, Validator[]>, values: Record<T, string>) => {
//  return validate(specs, values)
//    .flat(1)
//    .every((v) => v === undefined)
//}


export const validate = (value: string, validators: Validator[]) => {
  return validators?.map((validator) => {
    return validator(value)
  })
}