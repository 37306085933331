import {MutableRefObject, useEffect} from "react"
import {dragContext} from "./useDrag"

let installed = false

const dropZonesData = new Map()

let dropLine: any
let prevDropZone: any
let prevDirection: any

const useDrop = (elementRef: MutableRefObject<any>, onDrop: (dropData: any, direction: 'above' | 'below' | undefined) => any) => {

  useEffect(() => {
    elementRef.current && (elementRef.current.dataset.dropZone = true)
    elementRef.current && dropZonesData.set(elementRef.current, onDrop)
    return () => {
      elementRef.current && (delete elementRef.current.dataset.dropZone)
      elementRef.current && dropZonesData.delete(elementRef.current)
    }
  }, [elementRef.current, onDrop])

  if (!installed) {

    const onPointerUp = () => {
      const context = dragContext.current
      if (context.dragging) {
        stopScroll()
        const clientX = context.x + context.sourceX
        const clientY = context.y + context.sourceY
        context.dragging = false
        context.x = 0
        context.y = 0
        context.draggedElement && (context.draggedElement.style.opacity = "")
        context.preview?.remove()
        dropLine && dropLine.remove()
        const target = context.dropTarget
        context.dropTarget = null
        const dropZone = target?.closest(`[data-drop-zone="true"]`) as HTMLDivElement
        const onDrop = dropZonesData.get(dropZone)
        onDrop?.(context.dragData, context.direction)
      }
    }

    document.addEventListener(`mouseup`, onPointerUp)
    document.addEventListener('touchend', onPointerUp)
    document.addEventListener('touchcancel', onPointerUp)

    const onPointerMove = (e: any) => {

      const {clientX, clientY} = e.touches ? e.touches[0] : e
      const context = dragContext.current

      if (context.dragging) {

        context.x = clientX - context.sourceX
        context.y = clientY - context.sourceY
        context.preview && (context.preview.style.transform = `translate(${context.x}px, ${context.y}px)`)

        if (context.scrollParent) {
          const rect = context.scrollParent.getBoundingClientRect()
          const distanceFromTop = clientY - rect.top
          const distanceFromBottom = clientY - rect.bottom
          if (distanceFromTop > 0 && distanceFromTop < 60) {
            scroll(context.scrollParent, 'up')
          } else if (distanceFromBottom < 0 && distanceFromBottom > -60) {
            scroll(context.scrollParent, 'down')
          } else {
            stopScroll()
          }
        }

        const target = document.elementFromPoint(clientX, clientY) //e.target seems to be wrong on mobile (https://stackoverflow.com/questions/3918842/how-to-find-out-the-actual-event-target-of-touchmove-javascript-event)
        const dropZone = target?.closest(`[data-drop-zone="true"]`) as HTMLDivElement

        if (!dropZone || dropZone === context.draggedElement) {
          return
        }

        context.dropTarget = target;

        const hoverBoundingRect = dropZone.getBoundingClientRect()
        const hoverMiddleY = hoverBoundingRect.top + ((hoverBoundingRect.bottom - hoverBoundingRect.top) / 2)

        if (clientY < hoverMiddleY) {
          context.direction = 'above'
          if (dropZone !== prevDropZone && context.direction !== prevDirection) {
            dropLine?.remove()
            dropLine = createDropLine(dropZone)
            const rect = dropZone.getBoundingClientRect()
            dropLine.style.top = rect.top + 'px'
            dropZone.parentElement?.prepend(dropLine)
          }
        }

        if (clientY >= hoverMiddleY) {
          context.direction = 'below'
          if (dropZone !== prevDropZone && context.direction !== prevDirection) {
            dropLine?.remove()
            dropLine = createDropLine(dropZone)
            const rect = dropZone.getBoundingClientRect()
            dropLine.style.top = rect.bottom + 'px'
            dropZone.parentElement?.append(dropLine)
          }
        }

      }
    }

    document.addEventListener(`mousemove`, onPointerMove)
    document.addEventListener('touchmove', onPointerMove)

    installed = true

  }

}

let scrolling = false

const stopScroll = () => {
  scrolling = false
}

const scroll = (el: any, direction: any) => {
  const scroll = () => {
    requestAnimationFrame(() => {
      el.scrollBy(0, direction === 'up' ? -7 : 7)
      scrolling && scroll()
    })
  }
  if (!scrolling) {
    scrolling = true
    scroll()
  }
}

const createDropLine = (dropZone: HTMLElement) => {
  const dl = document.createElement('div');
  dl.style.height = '4px'
  dl.style.width = dropZone.offsetWidth + 'px'
  dl.style.transform = "translateY(-3px)"
  dl.style.background = "blue"
  dl.style.pointerEvents = "none"
  dl.style.position = "absolute"
  return dl
}

export default useDrop
