import React from "react"
import {css, cx} from "@emotion/css"
import {columns} from "../../styles/styleUtils"

import {Filters as FiltersType} from "../../store/Store"

import FiltersHeading from "./DesktopFiltersHeading"
import FiltersBody from "./FiltersBody"

export interface FiltersProps {
  nonBlockingFilters: FiltersType,
  setNonBlockingFilters: React.Dispatch<React.SetStateAction<FiltersType>>
  startFilteringTransition: React.TransitionStartFunction
}

const Filters = ({nonBlockingFilters, setNonBlockingFilters, startFilteringTransition}: FiltersProps) => {
  return <div className={cx(columns, css`height:100%;`)}>
    <FiltersHeading
      nonBlockingFilters={nonBlockingFilters}
      setNonBlockingFilters={setNonBlockingFilters}
      startFilteringTransition={startFilteringTransition}
    />
    <FiltersBody
      className={css`flex: 1; overflow: auto;`}
      nonBlockingFilters={nonBlockingFilters}
      setNonBlockingFilters={setNonBlockingFilters}
      startFilteringTransition={startFilteringTransition}
    />
  </div>
}

export default Filters