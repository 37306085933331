import Button from "../../components/ui-kit/Button"
import React from "react"
import useModal from "../../hooks/useBoolean"
import DeleteTagsModal from "./DeleteTagsModal"
import useStore from "../../store/useStore"
import useEvent from "../../hooks/useEvent"

interface AddTagsButton {
  selectedBookmarkIds: Set<string>
}

const OpenBookmarksButton = ({selectedBookmarkIds}: AddTagsButton) => {

  const bookmarksIndex = useStore((s) => s.entities.bookmark)

  const onClick = useEvent(() => {
    for (const bookmarkId of Array.from(selectedBookmarkIds)) {
      window.open(bookmarksIndex[bookmarkId].url)
    }
  })

  return <>
    <Button size="large" onClick={onClick}>Open</Button>
  </>
}

export default OpenBookmarksButton