import {ElementType} from "react"
import Icon from "./Icon"
import Button, {ButtonProps} from "./Button"
import {IconDefinition} from "@fortawesome/fontawesome-common-types"

export interface IconButtonProps extends ButtonProps {
  glyph: ElementType | IconDefinition
}

const IconButton = ({glyph, size = 'medium', ...rest}: IconButtonProps) => {
  return <Button
    variant="tertiary"
    ratio="1:1"
    size={size}
    {...rest}
  >
    <Icon glyph={glyph} size={size}/>
  </Button>
}

export default IconButton