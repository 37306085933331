import React, {useEffect, useRef, useState} from "react";
import {css} from "@emotion/css";

const FavIcon = ({url, className}: { url: string, className?: string }) => {

  const ref = useRef<HTMLImageElement>(null)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  useEffect(() => {

    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0
    }

    // @ts-ignore
    const handleIntersect = (entries, observer) => {
      // @ts-ignore
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersect, options);
    ref.current && observer.observe(ref.current);

    return () => {
      ref.current && observer.unobserve(ref.current)
      observer.disconnect()
    }

  }, [])

  const [src, setSrc] = useState<string>()
  return <div ref={ref} className={css`width: 16px; height: 16px;`}>
    {isVisible && <img
      src={src || createFaviconSrc(url)}
      onError={() => {
        setSrc(`https://s2.googleusercontent.com/s2/favicons?domain_url=${url}&size=16`)
      }}
      className={className}
    />}
  </div>
}

const createFaviconSrc = (url: string) => {
  return url && `${new URL(url).origin}/favicon.ico`
}

export default React.memo(FavIcon)
