import {HTMLAttributes} from "react"
import {cx, css} from "@emotion/css"
import {placeholderBaseStyles} from "./Placeholder"

export interface InputPlaceholderProps extends HTMLAttributes<HTMLDivElement> {
}

const InputPlaceholder = ({className, ...rest}: InputPlaceholderProps) => {
  return <div className={cx(placeholderBaseStyles, css`width: 100%; height: 22px;`, className)} {...rest}/>
}

export default InputPlaceholder