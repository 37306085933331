import "./cssReset.css"
import "./styles"
import Filters from "./routes/main/Filters"
import React, {startTransition, useEffect, useMemo, useState, useTransition} from "react"
import useStore from "./store/useStore"
import {cx, css} from "@emotion/css"
import {columns, gap3, p3, pb3, px3, rows} from "./styles/styleUtils"
import SettingsModal from "./routes/main/SettingsModal"
import MobileBodyPlaceholder from "./routes/main/MobileBodyPlaceholder"
import Bookmarks from "./routes/main/Bookmarks"
import initialState from "./store/initialState"
import useResponsiveBreakpoints from "./hooks/useResponsiveBreakpoints"
import useModal from "./hooks/useBoolean"
import ProductHeading from "./routes/main/ProductHeading"
import FiltersModal from "./routes/main/FiltersModal"
import MobileFiltersHeading from "./routes/main/MobileFiltersHeading"
import useBookmarks from "./hooks/useBookmarks"
import areFiltersEmpty from "./utils/areFiltersEmpty"
import createEditingSession from "./api/createEditingSession"
import Onboard from "./routes/main/Onboard"
import auth from "./api/auth"
import EmptyBookmarks from "./routes/main/EmptyBookmarks"
import localforage from "localforage"
import produce from "immer"
import {baseUrl} from "./index"
import containWidth from "./styles/containWidth";
import DesktopBodyPlaceholder from "./routes/main/DesktopBodyPlaceholder";
import useEvent from "./hooks/useEvent";
import A from "./components/ui-kit/A"

/*
todo
  - re-imports are a bit odd, I'm making titles in file win, but I'm merging tags and bookmarkTags
  - update filters when deleting tags (delete after filter applied)
  - think of how to simplify import
  - copy filters when create
  - improve text filter
*/

const cacheKey = 'appCache'
export let api = createEditingSession()

function App() {

  const [loadingCache, setLoadingCache] = useState(true)
  const [loadingRemoteData, setLoadingRemoteData] = useState(true)
  const [importing, setImporting] = useState(false)
  const [nonBlockingFilters, setNonBlockingFilters] = useState(initialState.ui.filters)
  const [, startFilteringTransition] = useTransition()
  useResponsiveBreakpoints()

  useEffect(() => {
    const initApp = async () => {
      if (window.location.pathname.includes('/verify')) {
        const urlParams = new URLSearchParams(window.location.search);
        const uuid = urlParams.get('uuid');
        if (uuid) {
          const {data} = await auth.verify({uuid})
          data && localStorage.setItem("authToken", data)
          await localforage.setItem(cacheKey, null)
          window.location.replace(window.location.origin)
        }
      } else {
        const authToken = localStorage.getItem("authToken");
        if (authToken) {
          useStore.subscribe((state) => {
            localforage.setItem(cacheKey, state.entities)
          })
          const appCache = await localforage.getItem(cacheKey)
          if (appCache) {
            startTransition(() => {
              useStore.setState(produce((s) => {
                s.entities = appCache
              }))
              setLoadingCache(false)
            })
          }
          await api.init({baseUrl})
          setLoadingRemoteData(false)
          !appCache && setLoadingCache(false)
        }
      }
    }
    initApp()
  }, [])

  const [showSettingsModal, onSettingsModalOpen, onSettingsModalClose] = useModal()
  const [showFiltersModal, onFiltersModalOpen, onFiltersModalClose] = useModal()
  const [showSignInModal, onOpenSignInModal, onCloseSignInModal] = useModal()
  const [renderAmount, setRenderAmount] = useState(100)

  const breakpoint = useStore((s) => s.ui.activeBreakpoint)

  const filters = useStore((s) => s.ui.filters)
  const emptyFilters = areFiltersEmpty(filters)
  let filteredBookmarks = useBookmarks()

  const bookmarksToRender = useMemo(() => filteredBookmarks.slice(0, renderAmount), [filteredBookmarks, renderAmount]);

  const isDesktop = breakpoint === 'desktop'

  const onFullyScrolled = useEvent(() => {
    setRenderAmount((amount) => amount < filteredBookmarks.length ? amount + 100 : amount)
  })

  const [selectedBookmarkIds, setSelectedBookmarkIds] = useState<Set<string>>(new Set([]))

  //todo use a callback instead
  useEffect(() => {
    setRenderAmount(100)
    setSelectedBookmarkIds(new Set())
  }, [filters.title, filters.tagIds.size])

  const bookmarksComponent = <Bookmarks
    bookmarksToRender={bookmarksToRender}
    filteredBookmarks={filteredBookmarks}
    setNonBlockingFilters={setNonBlockingFilters}
    onFullyScrolled={onFullyScrolled}
    selectedBookmarkIds={selectedBookmarkIds}
    setSelectedBookmarkIds={setSelectedBookmarkIds}
  />

  return <>
    <div className={cx(columns, css`height: 100%;`, !isDesktop && css`background: white;`)}>
      <div className={cx(css`background: white; border-bottom: 1px solid var(--coolGrey3);`)}>
        <div className={cx(isDesktop && containWidth)}>
          <ProductHeading
            loading={loadingRemoteData}
            showSignInModal={showSignInModal}
            onOpenSignInModal={onOpenSignInModal}
            onCloseSignInModal={onCloseSignInModal}
            onSettingsClick={onSettingsModalOpen}
          />
        </div>
      </div>
      <div className={cx(css`flex:1; overflow: auto;`)}>
        {(() => {
          if (!localStorage.getItem("authToken")) {
            return <Onboard onOpenSignInModal={onOpenSignInModal}/>
          }
          if (loadingCache) {
            return isDesktop ? <div className={cx(css`height: 100%;`, isDesktop && containWidth)}><DesktopBodyPlaceholder/></div> : <MobileBodyPlaceholder/>
          }
          if (importing || bookmarksToRender.length === 0 && emptyFilters ) {
            return <EmptyBookmarks importing={importing} setImporting={setImporting}/>
          }
          if (isDesktop) {
            return <div className={cx(css`height: 100%;`, isDesktop && containWidth)}>
              <div className={cx(rows, p3, gap3, css`flex: 1; height: 100%;`)}>
                <div className={cx(columns, css`flex: 0 0 310px; height: 100%;`)}>
                  <div
                    className={cx(columns, css`flex: 0; background: white; border-radius: 6px; border: 1px solid var(--coolGrey3); height: 100%;`)}>
                    <Filters
                      nonBlockingFilters={nonBlockingFilters}
                      setNonBlockingFilters={setNonBlockingFilters}
                      startFilteringTransition={startFilteringTransition}
                    />
                  </div>
                </div>
                <div className={cx(columns, css`flex: 1;`)}>
                  <div
                    className={cx(columns, css`flex: 0; background: white; border-radius: 6px; border: 1px solid var(--coolGrey3); height: 100%;`)}>
                    {bookmarksComponent}
                  </div>
                </div>
              </div>
            </div>
          } else {
            return <div className={cx(columns,css`height: 100%;`)}>
              {showFiltersModal && <FiltersModal
                onClose={onFiltersModalClose}
                nonBlockingFilters={nonBlockingFilters}
                setNonBlockingFilters={setNonBlockingFilters}
                startFilteringTransition={startFilteringTransition}
              />}
              <MobileFiltersHeading
                onFiltersModalOpen={onFiltersModalOpen}
                startFilteringTransition={startFilteringTransition}
              />
              <div className={css`flex: 1; overflow: auto;`}>
                {bookmarksComponent}
              </div>
            </div>
          }
        })()}
      </div>
      <div>
        <div className={cx(...(isDesktop ? [containWidth, px3, pb3, css`text-align: right;`] : [p3, css`border-top: 1px solid var(--coolGrey3);`,css`text-align: center;`]))}>
          <div>🔬 Made with love ❤️ by <A href="https://x.com/cello3m" target="_blank">cello3m</A>, this project is free to use 🌈 to support it 🙏 share it around 📢 or <A href="https://buymeacoffee.com/cello3m" target="_blank">buy me a coffee ☕️</A></div>
        </div>
      </div>
    </div>
    {showSettingsModal && <SettingsModal onClose={onSettingsModalClose}/>}
  </>

}

export default App
