import React, {useEffect, useRef, useState} from "react"
import Tile from "../../components/ui-kit/Tile"
import {cx, css} from "@emotion/css"
import IconButton from "../../components/ui-kit/IconButton"
import {MdClose} from "react-icons/md"
import Label from "../../components/ui-kit/Label"
import Input from "../../components/ui-kit/Input"
import Button from "../../components/ui-kit/Button"
import Backdrop from "../../components/ui-kit/Backdrop"
import {columns, gap1, gap2, gap3, p3, rows} from "../../styles/styleUtils"
import useStore from "../../store/useStore"
import * as R from "ramda"
import createUuid from "../../utils/createUuid"
import useEvent from "../../hooks/useEvent"
import useOnEscKeydown from "../../hooks/useEscKeydown"
import CreatableSelect from 'react-select/creatable';
import Hint from "../../components/ui-kit/Hint"
import {isUrl, isValid, notEmpty, validate} from "../../utils/validation"
import SubTitle from "../../components/ui-kit/SubTitle"
import useFocusTrap from "../../hooks/useFocusTrap"
import {api} from "../../App"
import editBookmark from "../../transactionCreators/patchBookmark"
import addBookmark from "../../transactionCreators/postBookmark"

interface AddBookmarkModalProps {
  bookmarkId?: string,
  onClose: () => void
}

const BookmarkModal = ({bookmarkId, onClose}: AddBookmarkModalProps) => {

  const bookmarksIndex = useStore((s) => s.entities.bookmark)
  const tagsIndex = useStore((s) => s.entities.tag)
  const bookmarksTagsIndex = useStore((s) => s.entities.bookmarkTag)
  const bookmarksTagsArray = Object.values(bookmarksTagsIndex)
  const selectedTagsIds = bookmarksTagsArray.filter((bt) => bt.bookmarkId === bookmarkId).map(({tagId}) => tagId)
  const bookmark = bookmarkId && bookmarksIndex[bookmarkId]
  const tagsArray = R.values(tagsIndex)
  const sortedTags = R.sortBy(R.prop("position"), tagsArray)
  const filteredTagIds = useStore((s) => s.ui.filters.tagIds)
  const selectedTags = bookmark ? selectedTagsIds.map((tagId) => tagsIndex[tagId]) : Array.from(filteredTagIds).map((tagId) => tagsIndex[tagId])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.title && setTitle(params.title)
    params.url && setUrl(params.url)
  }, [])

  const [title, setTitle] = useState<string>(bookmark ? bookmark.title : '')
  const [url, setUrl] = useState<string>(bookmark ? bookmark.url : 'https://')
  const [urlTouched, setUrlTouched] = useState<boolean>(false)

  const [tags, setTags] = useState(selectedTags.map((tag) => ({
    value: tag.id,
    label: tag.title
  })));

  const validateUrl = () => {
    return validate(url, [notEmpty, isUrl])
  }

  const isFormValid = () => {
    return isValid([
      validateUrl()
    ].flat())
  }

  const onSave = useEvent(() => {

    if (!isFormValid()) {
      setUrlTouched(true)
      return
    }

    const tagsToCreate = tags.filter((v: any) => v["__isNew__"]).map((val) => ({id: createUuid(), title: val.label}))
    const oldTagIds = selectedTagsIds
    const newTagIds = tags.filter((v: any) => !v["__isNew__"]).map(({value}) => tagsIndex[value].id)
    const tagIdsToAdd = R.difference(newTagIds, oldTagIds);

    if (bookmark) {
      const tagIdsToRemove = R.difference(oldTagIds, newTagIds);
      console.log({tagsToCreate, tagIdsToAdd, tagIdsToRemove})
      api.applyAndDispatch(editBookmark({bookmarkId, bookmark: {title, url}, tagsToCreate, tagIdsToAdd, tagIdsToRemove}))
    } else {
      console.log({tagsToCreate, tagIdsToAdd})
      api.applyAndDispatch(addBookmark({title, url, tagsToCreate, tagIdsToAdd}))
    }

    onClose()
  })

  const handleChange = useEvent((
    newValue: any
  ) => {
    // @ts-ignore
    setTags(newValue)
  });

  const options = sortedTags.map((t) => ({
    value: t.id,
    label: t.title
  }))

  useOnEscKeydown(onClose)

  const [isMenuOpen, setMenuOpen] = useState(false);

  const urlInputRef = useRef<HTMLInputElement>(null)

  const modalRef = useRef(null)
  useFocusTrap(modalRef)

  return <Backdrop>
    <Tile ref={modalRef} className={cx(columns, css(`width:100%; max-width: 420px; max-height:100%;`))}>
      <form onSubmit={(e) => {
        e.preventDefault()
        return false
      }}>
        <div
          className={cx(rows, p3, gap2, css`justify-content: space-between;
              align-items: flex-start;
              border-bottom: 1px solid var(--coolGrey3);`)}>
          <SubTitle level="l2">
            {bookmarkId === 'new' ? "Add" : "Edit"} bookmark
          </SubTitle>
          <IconButton glyph={MdClose} onClick={onClose}/>
        </div>
        <div className={cx(columns, p3, gap3)}>
          <div className={cx(columns, gap1)}>
            <Label>Title</Label>
            <Input
              autoFocus
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
              }}
            />
          </div>
          <div className={cx(columns, gap1)}>
            <Label>Url *</Label>
            <Input
              ref={urlInputRef}
              value={url}
              onFocus={() => {
                const input = urlInputRef.current
                if (input) {
                  const focusedViaKeyboard = !input.classList.contains('pressed')
                  if (focusedViaKeyboard) {
                    const value = input.value
                    if (value.startsWith("http://")) {
                      input.selectionStart = 7
                    } else if (value.startsWith("https://")) {
                      input.selectionStart = 8
                    } else {
                      input.selectionStart = 0
                    }
                  }
                }
              }}
              onChange={(e) => {
                setUrl(e.target.value)
                setUrlTouched(true)
              }}
            />
            {urlTouched && <Hint>{validateUrl()}</Hint>}
          </div>
          <div className={cx(columns, gap1)}>
            <Label>Tags</Label>
            <CreatableSelect
              tabSelectsValue={false}
              menuIsOpen={isMenuOpen}
              onMenuClose={() => setMenuOpen(false)}
              onMenuOpen={() => setMenuOpen(true)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !isMenuOpen) {
                  e.preventDefault() //prevents form submit
                  setMenuOpen(true)
                }
                if (e.key === "Escape" && isMenuOpen) {
                  e.stopPropagation();
                }
              }}
              closeMenuOnSelect={false}
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '4px',
                  outline: state.isFocused ? '3px solid var(--blue4)' : 'none'
                }),
                control: (provided) => ({
                  ...provided,
                  borderColor: 'var(--coolGrey4)!important',
                  boxShadow: 'none'
                })
              }}
              isMulti
              noOptionsMessage={() => <>No Tags, type to create a new one.</>}
              value={tags}
              onChange={handleChange}
              // @ts-ignore
              options={options}
            />
          </div>
        </div>
        <div
          className={cx(rows, p3, gap2, css`justify-content: flex-end;
              align-items: center;
              gap: 8px;
              border-top: 1px solid var(--coolGrey3);`)}>
          <Button variant="tertiary" onClick={onClose} size="large">Cancel</Button>
          <Button type="submit" variant="secondary" onClick={onSave} size="large">Save</Button>
        </div>
      </form>
    </Tile>
  </Backdrop>
}

export default BookmarkModal