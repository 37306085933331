import Store from "../store/Store"
import * as R from "ramda"
import {generateKeyBetween} from "fractional-indexing"
import createUuid from "../utils/createUuid"

interface PostBookmark {
  title: string
  url: string
  tagsToCreate: Array<{ id: string, title: string }>
  tagIdsToAdd: Array<string>
}

const editBookmark = ({title, url, tagsToCreate, tagIdsToAdd}: PostBookmark) => (entities: Store["entities"]) => {

  const tagsIndex = entities.tag
  const tags = R.values(tagsIndex)
  const sortedTags = R.sortBy(R.prop("position"), tags)
  const bookmarksTagsIndex = entities.bookmarkTag
  const bookmarksIndex = entities.bookmark
  const bookmarksArray = R.values(bookmarksIndex)
  const sortedBookmarksArray = R.sortBy(R.prop('position'), bookmarksArray)
  const firstBookmarkPosition = sortedBookmarksArray[0]?.position

  const result = []

  const bookmarkId = createUuid()

  result.push({
    method: 'post' as const,
    entity: 'bookmark' as const,
    data: {
      id: bookmarkId,
      title,
      url,
      position: generateKeyBetween(null, firstBookmarkPosition || null)
    }
  })

  let firstTagPosition = sortedTags[0]?.position || null

  for (const tag of tagsToCreate) {
    const position = generateKeyBetween(null, firstTagPosition)
    firstTagPosition = position
    result.push({
      method: 'post' as const,
      entity: 'tag' as const,
      data: {
        id: tag.id,
        title: tag.title,
        position: position
      }
    })
    result.push({
      method: 'post' as const,
      entity: 'bookmarkTag' as const,
      data: {
        id: bookmarkId + '_' + tag.id,
        bookmarkId,
        tagId: tag.id
      }
    })
  }

  for (const tagIdToAdd of tagIdsToAdd) {
    const btId = bookmarkId + '_' + tagIdToAdd
    if (!bookmarksTagsIndex[btId]) {
      result.push({
        method: 'post' as const,
        entity: 'bookmarkTag' as const,
        data: {
          id: bookmarkId + '_' + tagIdToAdd,
          bookmarkId,
          tagId: tagIdToAdd
        }
      })
    }
  }

  return result
}

export default editBookmark;