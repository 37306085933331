import {HTMLAttributes} from "react"
import useDisableScroll from "../../hooks/useDisableScroll"
import {cx, css} from "@emotion/css"
import {columns} from "../../styles/styleUtils"

export interface BackdropProps extends HTMLAttributes<HTMLDivElement> {
}

const Backdrop = ({children, className, ...props}: BackdropProps) => {

  useDisableScroll()

  return <div
    className={cx(
      columns,
      css`
        position: fixed;
        top: 0;
        left: 0;
        width:100%;
        height:100%;
        background: rgba(0, 0, 0, 0.25);
        padding: 6%;
        justify-content: flex-start;
        align-items: center;
      `,
      className
    )}
    {...props}
  >
    {children}
  </div>
}

export default Backdrop