import React, {useRef} from "react"
import Tile from "../../components/ui-kit/Tile"
import {cx, css} from "@emotion/css"
import IconButton from "../../components/ui-kit/IconButton"
import Label from "../../components/ui-kit/Label"
import Button from "../../components/ui-kit/Button"
import Backdrop from "../../components/ui-kit/Backdrop"
import {
  columns,
  gap2,
  pb1,
  pb3,
  pt2,
  pt3,
  px3,
  py3,
  rows
} from "../../styles/styleUtils"
import useStore from "../../store/useStore"
import useEvent from "../../hooks/useEvent"
import useOnEscKeydown from "../../hooks/useEscKeydown"
import SubTitle from "../../components/ui-kit/SubTitle"
import createUuid from "../../utils/createUuid"
import * as R from "ramda"
import TagListItem from "./TagListItem"

import {MdClose} from "react-icons/md"
import useFocusTrap from "../../hooks/useFocusTrap"
import {api} from "../../App"
import postTag from "../../transactionCreators/postTag"

interface TagsModalProps {
  onClose: () => void
}

const TagsModal = ({onClose}: TagsModalProps) => {

  const tagsIndex = useStore((s) => s.entities.tag)
  const tagsArray = Object.values(tagsIndex)
  const sortedTags = R.sortBy(R.prop("position"), tagsArray)

  const onTagAdd = useEvent(() => {
    const tagUuid = createUuid()
    api.applyAndDispatch(postTag({tagUuid}))
    setTimeout(() => {
      const tagTitleInput = document.querySelector(`#tag-title-input-${tagUuid}`) as HTMLInputElement
      tagTitleInput?.focus()
      tagTitleInput?.select()
    }, 0)
  })

  const onKeyDown = useEvent((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onTagAdd()
    }
  })

  useOnEscKeydown(onClose)

  const modalRef = useRef(null)
  useFocusTrap(modalRef)

  return <Backdrop>
    <Tile ref={modalRef} className={cx(columns, css(`width:100%; max-width: 420px; max-height:100%;`))}>
      <div className={cx(rows, px3, pt3, pb1, css`justify-content: space-between; align-items: flex-start;`)}>
        <SubTitle level="l2">
          Edit tags
        </SubTitle>
        <IconButton glyph={MdClose} onClick={onClose}/>
      </div>
      <div className={cx(columns, css`flex:1; overflow: auto;`)}>
        <div
          className={cx(rows, px3, pb3, pt2, gap2, css`justify-content: space-between; border-bottom: 1px solid var(--coolGrey3);`)}>
          <Label>Tags</Label>
          <Button autoFocus variant="primary" size='large' onClick={onTagAdd}>Add</Button>
        </div>
        <div className={cx(columns, py3, css`flex:1; overflow: auto;`)}>
          {sortedTags.length === 0 && <div className={cx(px3)}>There are no tags, click "Add" to get started.</div>}
          {sortedTags.map(({id}, i) => {
            return <div key={id}>
              <TagListItem
                id={id}
                prevPosition={sortedTags[i - 1]?.position}
                nextPosition={sortedTags[i + 1]?.position}
                onKeyDown={onKeyDown}
              />
            </div>
          })}
        </div>
      </div>
    </Tile>
  </Backdrop>
}

export default TagsModal