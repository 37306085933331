const runAsync = async <T>(fn: () => T) => {
  return new Promise<T>((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve(fn())
      } catch (e) {
        reject(e)
      }
    }, 0)
  })
}

export default runAsync