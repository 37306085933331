import React, {ForwardedRef, HTMLAttributes} from "react"
import {cx, css} from "@emotion/css"
import {gap2, p3, rows} from "../../styles/styleUtils"
import Button from "../../components/ui-kit/Button"
import useStore from "../../store/useStore"
import areFiltersEmpty from "../../utils/areFiltersEmpty"
import {hasUnfilteredStyles} from "./DesktopFiltersHeading"
import refreshFilters from "../../reducers/refreshFilters"

export interface MobileFiltersHeadingProps extends HTMLAttributes<HTMLDivElement> {
  onFiltersModalOpen: () => void
  startFilteringTransition: React.TransitionStartFunction
}

const _MobileFiltersHeading = ({className, onFiltersModalOpen, startFilteringTransition,...rest}: MobileFiltersHeadingProps, ref: ForwardedRef<HTMLDivElement>) => {

  const filters = useStore((s) => s.ui.filters)
  const unfilteredIds = useStore((s) => s.ui.unfilteredIds)
  const hasUnfilteredIds = unfilteredIds.size > 0
  const {title, tagIds} = filters
  const tagsIndex = useStore((s) => s.entities.tag)
  const tags = Array.from(tagIds).map((tagId) => tagsIndex[tagId])

  const filtersEmpty = areFiltersEmpty(filters)
  const filtersDescriptions = [
    title && `title "${title}"`,
    tags.length > 0 && `tags "${tags.map(tag => tag.title).join(', ')}"`
  ].filter(Boolean).join(' and ')

  const hasUnfiltered = hasUnfilteredIds && !areFiltersEmpty(filters)

  return <div ref={ref} className={cx(rows, p3, gap2, css`justify-content: space-between; align-items: center; border-bottom: 1px solid var(--coolGrey3);`, className)} {...rest}>
    <div>
      {!filtersEmpty && "Filtering by: " + filtersDescriptions}
    </div>
    <Button
      className={cx(hasUnfiltered && hasUnfilteredStyles)}
      variant="secondary" onClick={hasUnfiltered ? () => refreshFilters({startTransition:startFilteringTransition}) : onFiltersModalOpen}
    >
      {hasUnfiltered ? 'Reapply filters' : `${filtersEmpty ? 'Filter' : 'Edit filters'}`}
    </Button>

  </div>
}

const MobileFiltersHeading = _MobileFiltersHeading

export default React.memo(React.forwardRef(MobileFiltersHeading))
