import {HTMLAttributes} from "react"
import {css, cx} from "@emotion/css"

export interface SubTitleProps extends HTMLAttributes<HTMLDivElement> {
  level?: 'l1' | 'l2' | 'l3'
}

const SubTitle = ({level = 'l2', children, className}: SubTitleProps) => {
  return <div
    className={cx(createLevelStyles(level), css`font-weight: 600;`, className)}
  >
    {children}
  </div>
}

const createLevelStyles = (level: 'l1' | 'l2' | 'l3') => {
  if (level === `l1`) {
    return css`
      font-size: 27.04px;
      line-height: 36px;  
    `
  } else if (level === `l2`) {
    return css`
      font-size: 23.04px;
      line-height: 32px;
    `
  } else {
    return css`
      font-size: 19.2px;
      line-height: 28px;
    `
  }
}

export default SubTitle