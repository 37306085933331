import React, {useRef, useState} from "react"
import Tile from "../../components/ui-kit/Tile"
import {cx, css} from "@emotion/css"
import IconButton from "../../components/ui-kit/IconButton"
import Label from "../../components/ui-kit/Label"
import Backdrop from "../../components/ui-kit/Backdrop"
import {columns, gap2, gap3, p3, rows} from "../../styles/styleUtils"
import useOnEscKeydown from "../../hooks/useEscKeydown"
import SubTitle from "../../components/ui-kit/SubTitle"
import ImportButton from "./ImportButton"
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark"
import BookmarkletLink from "./BookmarkletLink"
import useFocusTrap from "../../hooks/useFocusTrap"

interface AddBookmarkModalProps {
  onClose: () => void
}

const SettingsModal = ({onClose}: AddBookmarkModalProps) => {

  const [importing, setImporting] = useState(false)

  useOnEscKeydown(onClose)

  const modalRef = useRef(null)
  useFocusTrap(modalRef)

  return <Backdrop>
    <Tile ref={modalRef} className={cx(columns, css(`width:100%; max-width: 420px; max-height:100%;`))}>
      <div
        className={cx(rows, p3, gap2, css`justify-content: space-between; align-items: flex-start; border-bottom: 1px solid var(--coolGrey3);`)}>
        <SubTitle level="l2">
          Settings
        </SubTitle>
        <IconButton glyph={faXmark} onClick={onClose}/>
      </div>
      <div className={cx(columns, css`flex:1; overflow: auto;`)}>
        <div className={cx(columns, p3, gap3)}>
          <div><Label>Install quick "Add Bookmark" button:</Label></div>
          <div>
            Drag "<BookmarkletLink/>" to your bookmarks bar to install a quick add button
          </div>
          <div><Label>Import bookmarks from chrome:</Label></div>
          <ImportButton importing={importing} setImporting={setImporting}/>
          {/*
            <div>
              You can import your chrome bookmarks.<br/><br/>
              Just drag the following link to your bookmarks bark to install it <A href={bookmarklet}>Add bookmark</A>.
              Importing bookmarks from other browsers is not supported yet, this feature will be added at a later
              date.<br/><br/>
            </div>
            */}
        </div>
      </div>
    </Tile>
  </Backdrop>
}

export default SettingsModal
