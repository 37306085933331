import Store from "../store/Store"

const deleteBookmarkTag = ({id}: { id: string }) => (entities: Store["entities"]) => {
  return [{
    method: 'delete' as const,
    entity: 'bookmarkTag' as const,
    data: {
      id
    }
  }]
}

export default deleteBookmarkTag