import React, {ChangeEvent, useRef} from "react"
import {cx, css} from "@emotion/css"
import IconButton from "../../components/ui-kit/IconButton"
import Input from "../../components/ui-kit/Input"
import {gap2, px3, py1, rows} from "../../styles/styleUtils"
import useStore from "../../store/useStore"
import useEvent from "../../hooks/useEvent"
import Icon from "../../components/ui-kit/Icon"
import {generateKeyBetween} from "fractional-indexing"
import useDrag from "../../hooks/useDrag"
import useDrop from "../../hooks/useDrop"
import {MdDelete, MdDragIndicator} from "react-icons/md"
import debounce from "lodash.debounce"
import {api} from "../../App"
import patchTagTitle from "../../transactionCreators/patchTagTitle"
import deleteTag from "../../transactionCreators/deleteTag"
import patchTagPosition from "../../transactionCreators/patchTagPosition"

interface TagListItemProps {
  id: string
  prevPosition?: string
  nextPosition?: string
  onKeyDown: (e: React.KeyboardEvent) => void
}

const TagListItem = ({id, prevPosition, nextPosition, onKeyDown}: TagListItemProps) => {

  const tag = useStore((s) => s.entities.tag[id])

  const {title, position} = tag

  const debouncedDispatchRef = useRef<any>()
  if (!debouncedDispatchRef.current) {
    debouncedDispatchRef.current = debounce((actionCreator) => {
      api.dispatch(actionCreator)
    }, 300)
  }

  const idRef = useRef();

  const onTagChange = useEvent((e: ChangeEvent<HTMLInputElement>, id: string) => {
    const actionCreator = patchTagTitle({id, title: e.target.value})
    idRef.current && api.discard(idRef.current)
    const _id = api.apply(actionCreator)
    idRef.current = _id
    debouncedDispatchRef?.current?.(_id)
  })

  const onTagDelete = useEvent((e: MouseEvent, tagId: string) => {
    api.applyAndDispatch(deleteTag({id: tagId}))
  })

  const handleRef = useRef<HTMLDivElement>(null)
  const previewRef = useRef<HTMLDivElement>(null)

  const onDrop = useEvent((dragData: any, direction: any) => {
    const {id} = dragData
    if (direction === 'above') {
      api.applyAndDispatch(patchTagPosition({id, position: generateKeyBetween(prevPosition || null, position || null)}))
    } else {
      api.applyAndDispatch(patchTagPosition({id, position: generateKeyBetween(position || null, nextPosition || null)}))
    }
  })

  useDrag(handleRef, previewRef, {id})
  useDrop(previewRef, onDrop)

  return <div
    ref={previewRef}
    className={cx(
      rows,
      gap2,
      px3,
      py1,
      css`
        align-items:center; 
        cursor:pointer; 
        &:hover{background:var(--coolGrey1);}
        &:focus-within{background:var(--coolGrey1);}
      `
    )}
  >
    <div ref={handleRef}>
      <Icon className={css`cursor: grab;`} glyph={MdDragIndicator}/>
    </div>
    <Input
      className={css`background: transparent; border-color:transparent;`}
      id={"tag-title-input-" + id}
      value={title}
      onChange={(e) => onTagChange(e, id)}
      onKeyDown={onKeyDown}
    />
    <IconButton onClick={(e) => onTagDelete(e, id)} glyph={MdDelete}/>
  </div>

}

export default React.memo(TagListItem)