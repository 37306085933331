import {css, cx as _cx} from "@emotion/css"
import styleUtils from "../../styles/styleUtils"
import {ReactNode} from "react"

type StyleUtils = keyof typeof styleUtils

type ElProps = {
  [styleUtils in StyleUtils]?: boolean
} & {
  as?: string
  s?: string
  children?: ReactNode
  className?: string
}

const El = ({as = 'div', s, className, ...rest}: ElProps) => {
  const keys = Object.keys(rest)
  const classes = []
  const props = {}
  for (const key of keys) {
    // @ts-ignore
    const cssClass = styleUtils[key]
    if (cssClass) {
      classes.push(cssClass)
    } else {
      // @ts-ignore
      props[key] = rest[key]
    }
  }
  const As = as
  // @ts-ignore
  return <As className={_cx(...classes, css(s), className)} {...props}/>
}

export default El