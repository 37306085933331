import React from "react"

import {faMagicWandSparkles} from "@fortawesome/free-solid-svg-icons/faMagicWandSparkles"
import SubTitle from "../../components/ui-kit/SubTitle"
import Icon from "../../components/ui-kit/Icon"
import {columns, gap2, gap4, p2, p4, rows} from "../../styles/styleUtils"
import {css, cx} from "@emotion/css"
import Button from "../../components/ui-kit/Button"

interface OnboardProps {
  onOpenSignInModal: () => {}
}

const Onboard = ({onOpenSignInModal}: OnboardProps) => {
  return <div className={cx(columns, gap4, p4, css`align-items:center;`)}>
    <SubTitle level="l1" className={cx(p2, css`display:inline; text-align: center;`)}>
      Save and organise your links with ease
      <Icon
        className={css`vertical-align:text-bottom; margin-left:8px;`}
        glyph={faMagicWandSparkles} size="xxl"
      />
    </SubTitle>
    <div className={cx(rows, gap2, css`align-items:center; justify-content:center; flex-wrap:wrap;`)}>
      <span className={css`text-align: center;`}><Button variant="tertiary" size="large" onClick={onOpenSignInModal}>Sign-in</Button> to get started</span>
    </div>
  </div>
}

export default Onboard
