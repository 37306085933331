import Button from "../../components/ui-kit/Button"
import React from "react"
import useModal from "../../hooks/useBoolean"
import DeleteTagsModal from "./DeleteTagsModal"

interface AddTagsButton {
  selectedBookmarkIds: Set<string>
}

const DeleteTagsButton = ({selectedBookmarkIds}: AddTagsButton) => {

  const [showEditBookmarksModal, onOpenEditBookmarksModal, onCloseEditBookmarksModal] = useModal()

  return <>
    {showEditBookmarksModal && <DeleteTagsModal selectedBookmarkIds={selectedBookmarkIds} onClose={onCloseEditBookmarksModal}/>}
    <Button size="large" onClick={onOpenEditBookmarksModal}>Delete tags</Button>
  </>
}

export default DeleteTagsButton