import React, {ForwardedRef, HTMLAttributes} from "react"
import {cx, css} from "@emotion/css"
import {gap1, p3, rows} from "../../styles/styleUtils"
import SubTitle from "../../components/ui-kit/SubTitle"
import IconButton from "../../components/ui-kit/IconButton"
import areFiltersEmpty from "../../utils/areFiltersEmpty"
import {MdClose, MdOutlineRefresh} from "react-icons/md"
import useStore from "../../store/useStore"
import {Filters as FiltersType} from "../../store/Store"
import clearFilters from "../../reducers/clearFilters"
import refreshFilters from "../../reducers/refreshFilters"

export const hasUnfilteredStyles = css`outline: 2px solid orange;`

export interface FiltersHeadingProps extends HTMLAttributes<HTMLDivElement> {
  nonBlockingFilters: FiltersType
  setNonBlockingFilters: React.Dispatch<React.SetStateAction<FiltersType>>
  startFilteringTransition: React.TransitionStartFunction
}

const _DesktopFiltersHeading = ({className, nonBlockingFilters, setNonBlockingFilters, startFilteringTransition, ...rest}: FiltersHeadingProps, ref: ForwardedRef<HTMLDivElement>) => {

  const filters = useStore((s) => s.ui.filters)
  const unfilteredIds = useStore((s) => s.ui.unfilteredIds)
  const hasUnfilteredIds = unfilteredIds.size > 0


  return <div
    ref={ref}
    className={cx(
      rows, p3, gap1,
      css`justify-content: space-between; align-items: center; border-bottom: 1px solid var(--coolGrey3);`,
      className
    )}
    {...rest}
  >
    <SubTitle level="l2">
      Filters
    </SubTitle>
    <div className={cx(rows,gap1)}>
      <IconButton
        glyph={MdClose}
        onClick={() => clearFilters({
          startTransition: startFilteringTransition,
          setNonBlockingFilters: setNonBlockingFilters
        })}
      />
      <IconButton
        className={cx(hasUnfilteredIds && !areFiltersEmpty(filters) && hasUnfilteredStyles)}
        glyph={MdOutlineRefresh}
        onClick={() => refreshFilters({startTransition: startFilteringTransition})}
      />
    </div>
  </div>
}

const DesktopFiltersHeading = _DesktopFiltersHeading

export default React.memo(React.forwardRef(DesktopFiltersHeading))