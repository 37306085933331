import Store from "../store/Store"
import * as R from "ramda"
import {generateKeyBetween} from "fractional-indexing"

const patchTagTitle = ({id, title}: { id: string, title: string }) => (entities: Store["entities"]) => {
  return [{
    method: 'patch' as const,
    entity: 'tag' as const,
    data: {id, title}
  }]
}

export default patchTagTitle