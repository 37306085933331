import useStore from "../store/useStore"
import {useMemo} from "react"
import * as R from "ramda"

const useBookmarks = () => {

  const filters = useStore((s) => s.ui.filters)
  const selectedTagIdsSet = filters.tagIds;
  const unfilteredIds = useStore((s) => s.ui.unfilteredIds)
  const bookmarksIndex = useStore((s) => s.entities.bookmark)
  const bookmarkTagsIndex = useStore((s) => s.entities.bookmarkTag)
  const bookmarksTagsArray = useMemo(() => Object.values(bookmarkTagsIndex), [bookmarkTagsIndex])
  const bookmarksTagsByBookmarkId = useMemo(() => R.groupBy(R.prop(`bookmarkId`), bookmarksTagsArray), [bookmarksTagsArray])
  const bookmarksArray = useMemo(() => Object.values(bookmarksIndex), [bookmarksIndex])

  const bookmarksFilteredByTitle = useMemo(() => {
    return bookmarksArray.filter((bookmark) => bookmark.title.match(new RegExp(filters.title, 'i')))
  }, [bookmarksArray, filters.title])

  const bookmarkIdsFilteredByTitle = useMemo(() => bookmarksFilteredByTitle.map((b) => b.id), [bookmarksFilteredByTitle])

  const bookmarksFilteredByTags = useMemo(() => {
    return bookmarksArray.filter((bookmark) => {
      let result = true;
      const bookmarksTags = bookmarksTagsByBookmarkId[bookmark.id] || []
      for (const selectedTagId of Array.from(selectedTagIdsSet)) {
        if (!(!!bookmarksTags.find(({tagId}) => tagId === selectedTagId))) {
          result = false
        }
      }
      return result
    })
  }, [bookmarksArray, bookmarksTagsByBookmarkId, selectedTagIdsSet])

  const bookmarkIdsFilteredByTags = useMemo(() => bookmarksFilteredByTags.map((b) => b.id), [bookmarksFilteredByTags])

  const filteredIds = useMemo(() => {
    return selectedTagIdsSet.size > 0
      ?
      R.intersection(
        bookmarkIdsFilteredByTitle,
        bookmarkIdsFilteredByTags
      )
      :
      bookmarkIdsFilteredByTitle
  }, [selectedTagIdsSet, bookmarkIdsFilteredByTitle, bookmarkIdsFilteredByTags])

  const bookmarkIds = useMemo(() => {
    return R.union(
      filteredIds,
      Array.from(unfilteredIds)
    )
  }, [filteredIds, unfilteredIds])

  const allBookmarks = useMemo(() => bookmarkIds.map((id) => bookmarksIndex[id]), [bookmarkIds, bookmarksIndex])

  return useMemo(() => R.sortBy(R.prop('position'), allBookmarks), [allBookmarks])

}

export default useBookmarks
