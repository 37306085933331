import React from "react"

import SubTitle from "../../components/ui-kit/SubTitle"
import AddBookmarkButton from "./AddBookmarkButton"
import ImportButton from "./ImportButton"
import {columns, gap2, gap4, p2, p4, rows} from "../../styles/styleUtils"
import {css, cx} from "@emotion/css"
import BookmarkletLink from "./BookmarkletLink"

const EmptyBookmarks = ({importing, setImporting}: { importing: boolean, setImporting: React.Dispatch<React.SetStateAction<boolean>> }) => {
  return <div className={cx(columns, gap4, p4, css`align-items:center;`)}>
    {!importing && <><SubTitle level="l1" className={cx(p2, css`display:inline; text-align: center;`)}>
      Ready to get started?
    </SubTitle>
      <div className={css`display:inline; text-align: center;`}>
        Drag "<BookmarkletLink/>" to your bookmarks bar to install a quick add button
      </div>
      <div className={cx(rows, gap2, css`align-items:center; justify-content:center; flex-wrap:wrap;`)}>
        <span className={css`text-align: center;`}>Add your first bookmark</span> <AddBookmarkButton/>
      </div>
      or
    </>}
    <div className={cx(rows, gap2, css`align-items:center; justify-content:center; flex-wrap:wrap;`)}>
      {!importing && <span className={css`text-align: center;`}>Import bookmarks from chrome</span>}
      <ImportButton importing={importing} setImporting={setImporting}/>
    </div>
  </div>

}

export default EmptyBookmarks
