import useStore from "../store/useStore"
import produce from "immer"
import Store from "../store/Store"
import React from "react"

export interface RefreshFilters {
  startTransition: React.TransitionStartFunction
}

const refreshFilters = ({startTransition}: RefreshFilters) => {
  startTransition(() => {
    useStore.setState(produce((store: Store) => {
      store.ui.unfilteredIds = new Set()
    }))
  })
}

export default refreshFilters

