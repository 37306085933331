type fetchParams = Parameters<typeof fetch>

const fetcher = async (url: fetchParams[0], options: fetchParams[1] = {}) => {
  const result = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken") || "",
      ...options.headers
    },
    ...options
  })

  if (localStorage.getItem("authToken") && result.status === 401) {
    localStorage.removeItem("authToken")
    window.location.reload()
  }

  if (!result.ok) {
    throw result
  }
  const json = await result.json()
  //return JSON.parse(json, dateTimeReviver)
  return json
}

const dateTimeReviver = (key: string, value: any) => {
  let a;
  if (typeof value === 'string') {
    a = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/.exec(value);
    if (a) {
      return new Date(a[0]);
    }
  }
  return value;
}

export default fetcher
