import React, {HTMLAttributes, ReactNode} from "react"
import {css, cx} from "@emotion/css"

export interface TagProps extends HTMLAttributes<HTMLDivElement> {
  left?: ReactNode
  right?: ReactNode
  color?: 'teal'
  disabled?: boolean
}

const Tag = ({children, className, color = 'teal', left, right, disabled, ...rest}: TagProps) => {
  return <div
    className={cx(
      createTagStyles(color),
      disabled && disabledStyles,
      className
    )}
    //disabled={disabled}
    {...rest}
  >
    {left}{children}{right}
  </div>
}

const createTagStyles = (color: 'teal') => css`
     
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 2px 4px;
  
  border-radius: 4px;
  
  background: var(--${color}2);
  color: var(--${color}8);
  border: 1px solid var(--${color}8);

  font-weight: normal;
  font-size: 11.11px;
  line-height: 16px;
  
`

const disabledStyles = css`
  color: var(--coolGrey5);
  cursor: not-allowed;
  background: var(--coolGrey3);
  &:hover{
    background: var(--coolGrey3);
  }
  &:active{
    background: var(--coolGrey3);
  }
`

export default React.memo(Tag)