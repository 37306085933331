import React, {useState, useEffect} from "react"
import BookmarkModal from "./BookmarkModal"
import Button from "../../components/ui-kit/Button"
import useEvent from "../../hooks/useEvent"
import {useLocation, useHistory} from "react-router-dom";
import {matchPath} from "react-router";

const bookmarkPath = '/bookmark'

const AddBookmarkButton = ({className}: { className?: string }) => {

  const [showAddBookmarkModal, setShowAddBookmarkModal] = useState(false)

  const [bookmarkId, setBookmarkId] = useState<string>()

  let location = useLocation();
  useEffect(() => {
    const match = matchPath<{ id: string }>(location.pathname, {
      path: bookmarkPath + "/:id",
      exact: true,
      strict: false
    })
    if (match) {
      setShowAddBookmarkModal(true)
      setBookmarkId(match.params.id)
    } else {
      setShowAddBookmarkModal(false)
    }
  }, [location])

  let history = useHistory();

  const onAdd = useEvent(() => {
    history.push(bookmarkPath + '/new');
  })

  const onClose = useEvent(() => {
    history.push('/');
  })

  return <>
    <Button className={className} size="large" onClick={onAdd}>Add</Button>
    {showAddBookmarkModal && <BookmarkModal bookmarkId={bookmarkId} onClose={onClose}/>}
  </>
}

export default AddBookmarkButton