import Store from "../store/Store"
import * as R from "ramda"
import {generateKeyBetween} from "fractional-indexing"
import createUuid from "../utils/createUuid"
import Bookmark from "../store/Bookmark"

interface PatchBookmark {
  bookmarkId: string
  bookmark?: Partial<Bookmark>
  tagsToCreate?: Array<{ id: string, title: string }>
  tagIdsToAdd?: Array<string>
  tagIdsToRemove?: Array<string>
}

const editBookmark = ({bookmarkId, bookmark, tagsToCreate = [], tagIdsToAdd = [], tagIdsToRemove = []}: PatchBookmark) => (entities: Store["entities"]) => {

  const bookmarksIndex = entities.bookmark
  const thisBookmark = bookmarksIndex[bookmarkId]
  const tagsIndex = entities.tag
  const tags = R.values(tagsIndex)
  const sortedTags = R.sortBy(R.prop("position"), tags)
  const bookmarksTagsIndex = entities.bookmarkTag
  const bookmarksTagsArray = Object.values(bookmarksTagsIndex)
  const result = []

  if (thisBookmark && bookmark) {
    result.push({
      method: 'patch' as const,
      entity: 'bookmark' as const,
      data: {
        id: bookmarkId,
        ...bookmark
      }
    })
  }

  let firstTagPosition = sortedTags[0]?.position || null

  for (const tag of tagsToCreate) {
    const position = generateKeyBetween(null, firstTagPosition)
    firstTagPosition = position
    result.push({
      method: 'post' as const,
      entity: 'tag' as const,
      data: {
        id: tag.id,
        title: tag.title,
        position: position
      }
    })
    if (thisBookmark) {
      result.push({
        method: 'post' as const,
        entity: 'bookmarkTag' as const,
        data: {
          id: bookmarkId + '_' + tag.id,
          bookmarkId,
          tagId: tag.id
        }
      })
    }
  }

  if (thisBookmark) {

    for (const tagIdToAdd of tagIdsToAdd) {
      const btId = bookmarkId + '_' + tagIdToAdd
      if (!bookmarksTagsIndex[btId]) {
        result.push({
          method: 'post' as const,
          entity: 'bookmarkTag' as const,
          data: {
            id: bookmarkId + '_' + tagIdToAdd,
            bookmarkId,
            tagId: tagIdToAdd
          }
        })
      }
    }

    const thisBookmarkTags = bookmarksTagsArray.filter((et) => et.bookmarkId === bookmarkId)
    const thisBookmarkTagsByTagId = R.indexBy(R.prop(`tagId`), thisBookmarkTags)

    for (const tagIdToRemove of tagIdsToRemove) {
      const bookmarkTagToRemove = thisBookmarkTagsByTagId[tagIdToRemove]
      bookmarkTagToRemove && result.push({
        method: 'delete' as const,
        entity: 'bookmarkTag' as const,
        data: {
          id: bookmarkTagToRemove.id
        }
      })
    }
  }

  return result
}

export default editBookmark;