import {css, cx} from "@emotion/css";
import {columns, gap3, p3, rows} from "../../styles/styleUtils";
import FiltersPlaceholder from "../../routes/main/FiltersPlaceholder";
import BookmarkPlaceholder from "../../routes/main/BookmarksPlaceholder";
import React from "react";

const DesktopBodyPlaceholder = () => {
  return <div className={cx(rows, p3, gap3, css`flex:1; height: 100%;`)}>
    <div className={cx(columns, css`flex: 0 0 310px; height: 100%;`)}>
      <div className={cx(columns, css`flex: 0; background: white; border-radius: 6px; border: 1px solid var(--coolGrey3); height: 100%;`)}>
        <FiltersPlaceholder/>
      </div>
    </div>
    <div className={cx(columns, css`flex: 1;`)}>
      <div className={cx(columns, css`flex: 0; background: white; border-radius: 6px; border: 1px solid var(--coolGrey3); height: 100%;`)}>
        <BookmarkPlaceholder/>
      </div>
    </div>
  </div>
}

export default DesktopBodyPlaceholder
