import Store from "../store/Store"
import * as R from "ramda"
import {generateKeyBetween} from "fractional-indexing"

const patchTagPosition = ({id, position}: { id: string, position: string }) => (entities: Store["entities"]) => {
  const tagsIndex = entities.tag
  const tags = R.values(tagsIndex)
  const itemWithSamePosition = tags.find((t) => t.position === position)
  if (itemWithSamePosition) {
    const sortedTags = R.sortBy(R.prop('position'), tags)
    const index = sortedTags.findIndex((t) => t === itemWithSamePosition)
    const nextPosition = sortedTags[index + 1]?.position
    position = generateKeyBetween(position || null, nextPosition || null)
  }
  return [{
    method: 'patch' as const,
    entity: 'tag' as const,
    data: {
      id,
      position
    }
  }]
}

export default patchTagPosition