import React, {ChangeEvent, ForwardedRef, HTMLAttributes, useMemo, useState, useTransition} from "react"
import {cx, css} from "@emotion/css"
import {columns, gap1, gap2, gap3, p2, p3, rows} from "../../styles/styleUtils"
import Label from "../../components/ui-kit/Label"
import Input from "../../components/ui-kit/Input"
import IconButton from "../../components/ui-kit/IconButton"
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons/faPenToSquare"
import TagsModal from "./TagsModal"
import Store, {Filters as FiltersType} from "../../store/Store"
import useStore from "../../store/useStore"
import * as R from "ramda"
import useEvent from "../../hooks/useEvent"
import produce from "immer"

export interface FiltersBodyProps extends HTMLAttributes<HTMLDivElement> {
  nonBlockingFilters: FiltersType,
  setNonBlockingFilters: React.Dispatch<React.SetStateAction<FiltersType>>
  startFilteringTransition: React.TransitionStartFunction
}

const _FiltersBody = ({className, nonBlockingFilters, setNonBlockingFilters, startFilteringTransition, ...rest}: FiltersBodyProps, ref: ForwardedRef<HTMLDivElement>) => {

  const tagsIndex = useStore((s) => s.entities.tag)
  const sortedTags = useMemo(() => R.sortBy(R.prop('position'), Object.values(tagsIndex)), [tagsIndex])

  const onFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setNonBlockingFilters(produce((filters: FiltersType) => {
      filters.title = e.target.value
    }))
    startFilteringTransition(() => {
      useStore.setState(produce((store: Store) => {
        store.ui.filters.title = e.target.value
        store.ui.unfilteredIds = new Set()
      }))
    })
  }

  const onTagSelection = (id: string) => {
    setNonBlockingFilters(produce((filters: FiltersType) => {
      filters.tagIds.has(id)
        ?
        filters.tagIds.delete(id)
        :
        filters.tagIds.add(id)
    }))
    startFilteringTransition(() => {
      useStore.setState(produce((store: Store) => {
        store.ui.filters.tagIds.has(id)
          ?
          store.ui.filters.tagIds.delete(id)
          :
          store.ui.filters.tagIds.add(id)
        store.ui.unfilteredIds = new Set()
      }))
    })
  }

  const [showEditTagsModal, setShowEditTagsModal] = useState(false)

  const onEditTagsModalOpen = useEvent(() => {
    setShowEditTagsModal(true)
  })

  const onEditTagsModalClose = useEvent(() => {
    setShowEditTagsModal(false)
  })

  return <div ref={ref} className={cx(columns, p3, gap3, className)} {...rest}>
    <div className={cx(columns, gap2)}>
      <Label>Title</Label>
      <Input placeholder="Filter by title" autoFocus value={nonBlockingFilters.title} onChange={onFilter}/>
    </div>
    <div className={cx(columns, gap3)}>
      <div className={cx(rows, gap2, css`justify-content: space-between; align-items: center;`)}>
        <Label>Tags</Label>
        <IconButton onClick={onEditTagsModalOpen} glyph={faPenToSquare}/>
        {showEditTagsModal && <TagsModal onClose={onEditTagsModalClose}/>}
      </div>
      <div className={cx(columns, gap2)}>
        {sortedTags.length === 0 && <div>There are no tags, click the edit icon to get started.</div>}
        {sortedTags.map((tag) => {
          return <div key={tag.id}>
            <label className={cx(rows, gap2, css`align-items:center; cursor:pointer;`)}>
              <input
                type="checkbox"
                checked={nonBlockingFilters.tagIds.has(tag.id)}
                onChange={() => onTagSelection(tag.id)}
              />
              {tag.title}
            </label>
          </div>
        })}
      </div>
    </div>
  </div>
}

const FiltersBody = _FiltersBody

export default React.memo(React.forwardRef(FiltersBody))