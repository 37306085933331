import useStore from "../../store/useStore"
import useEvent from "../../hooks/useEvent"
import produce from "immer"
import Store, {Filters as FiltersType} from "../../store/Store"
import React, {HTMLAttributes} from "react"

export interface UseFiltersHeadingActionsProps extends HTMLAttributes<HTMLDivElement> {
  setNonBlockingFilters: React.Dispatch<React.SetStateAction<FiltersType>>
  startFilteringTransition: React.TransitionStartFunction
}

const useFiltersHeadingActions = ({setNonBlockingFilters, startFilteringTransition}: UseFiltersHeadingActionsProps) => {

  const onRefreshFilters = useEvent(() => {
    startFilteringTransition(() => {
      useStore.setState(produce((store: Store) => {
        store.ui.unfilteredIds = new Set()
      }))
    })
  })

  const onClearFilters = useEvent(() => {
    setNonBlockingFilters(produce((filters: FiltersType) => {
      filters.title = ''
      filters.tagIds = new Set()
    }))
    startFilteringTransition(() => {
      useStore.setState(produce((store: Store) => {
        store.ui.filters = {
          title: '',
          tagIds: new Set()
        }
        store.ui.unfilteredIds = new Set()
      }))
    })
  })

  return {onClearFilters, onRefreshFilters}
}

export default useFiltersHeadingActions