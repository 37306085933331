import {HTMLAttributes} from "react"
import {cx, css} from "@emotion/css"

export interface TileProps extends HTMLAttributes<HTMLDivElement> {
}

const Divider = ({className, ...rest}: TileProps) => {
  return <div className={cx(css`height:1px; border-bottom: 1px solid var(--coolGrey3);`)} {...rest}/>
}

export default Divider