import React from "react"

import {cx, css} from "@emotion/css"
import {columns, gap1, p3, rows} from "../../styles/styleUtils"
import BookmarkPlaceholder from "./BookmarksPlaceholder"
import TagPlaceholder from "../../components/ui-kit/TagPlaceholder"

const MobileBodyPlaceholder = () => {

  return <div className={cx(columns, css`height:100%;`)}>
    <div
      className={cx(rows, p3, gap1, css`justify-content:flex-end; align-items: center; border-bottom: 1px solid var(--coolGrey3);`)}>
      <TagPlaceholder/>
    </div>
    <div
      className={cx(rows, p3, gap1, css`justify-content:flex-end; align-items: center; border-bottom: 1px solid var(--coolGrey3);`)}>
      <TagPlaceholder/>
    </div>
    <div className={css`flex:1; overflow: auto;`}>
      <BookmarkPlaceholder/>
      <BookmarkPlaceholder/>
      <BookmarkPlaceholder/>
      <BookmarkPlaceholder/>
      <BookmarkPlaceholder/>
      <BookmarkPlaceholder/>
    </div>
  </div>
}

export default MobileBodyPlaceholder
