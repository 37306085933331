import React, {useRef, useState} from "react"
import Tile from "../../components/ui-kit/Tile"
import {cx, css} from "@emotion/css"
import IconButton from "../../components/ui-kit/IconButton"
import {MdClose} from "react-icons/md"
import Label from "../../components/ui-kit/Label"
import Button from "../../components/ui-kit/Button"
import Backdrop from "../../components/ui-kit/Backdrop"
import {columns, gap1, gap2, gap3, p3, rows} from "../../styles/styleUtils"
import useStore from "../../store/useStore"
import * as R from "ramda"
import useEvent from "../../hooks/useEvent"
import useOnEscKeydown from "../../hooks/useEscKeydown"
import Select from 'react-select';
import SubTitle from "../../components/ui-kit/SubTitle"
import useFocusTrap from "../../hooks/useFocusTrap"
import {api} from "../../App"
import deleteBookmarkTag from "../../transactionCreators/deleteBookmarkTag"

interface AddBookmarkModalProps {
  selectedBookmarkIds: Set<string>
  onClose: () => void
}

const AddTagsModal = ({selectedBookmarkIds, onClose}: AddBookmarkModalProps) => {

  const bookmarkTagsIndex = useStore((s) => s.entities.bookmarkTag)
  const bookmarkTagsArray = R.values(bookmarkTagsIndex)
  const selectedBookmarkTags = bookmarkTagsArray.filter((bt) => selectedBookmarkIds.has(bt.bookmarkId))
  const selectedTagIds = Array.from(new Set([...selectedBookmarkTags.map((bt) => bt.tagId)]))
  const tagsIndex = useStore((s) => s.entities.tag)
  const selectedTags = selectedTagIds.map((tagId)=>tagsIndex[tagId])
  const sortedSelectedTags = R.sortBy(R.prop("position"), selectedTags)

  const [tags, setTags] = useState([]);

  const onSave = useEvent(async () => {
    //const remainingTagIds = tags.map(({value}) => tagsIndex[value].id)
    //const oldTagIds = sortedSelectedTags.map((t) => t.id)
    //new Set(R.difference(oldTagIds, remainingTagIds));
    const tagIdsToRemove = new Set(tags.map(({value}) => tagsIndex[value].id))
    const bookmarkTagsToRemove = selectedBookmarkTags.filter((bt) => tagIdsToRemove.has(bt.tagId))
    const bookmarkTagIdsToRemove = bookmarkTagsToRemove.map((bt) => bt.id)
    for (const bookmarkTagId of Array.from(bookmarkTagIdsToRemove)) {
      api.applyAndDispatch(deleteBookmarkTag({id: bookmarkTagId}))
    }
    onClose()
  })

  const handleChange = useEvent((
    newValue: any
  ) => {
    // @ts-ignore
    setTags(newValue)
  });

  const options = sortedSelectedTags.map((t) => ({
    value: t.id,
    label: t.title
  }))

  const [isMenuOpen, setMenuOpen] = useState(false);

  useOnEscKeydown(onClose)

  const modalRef = useRef(null)
  useFocusTrap(modalRef)

  return <Backdrop>
    <Tile ref={modalRef} className={cx(columns, css(`width:100%; max-width: 420px; max-height:100%;`))}>
      <form onSubmit={(e) => {
        e.preventDefault()
        return false
      }}>
        <div className={cx(rows, p3, gap2, css`justify-content: space-between; align-items: flex-start; border-bottom: 1px solid var(--coolGrey3);`)}>
          <SubTitle level="l2">
            Delete tags
          </SubTitle>
          <IconButton glyph={MdClose} onClick={onClose}/>
        </div>
        <div className={cx(columns, p3, gap3)}>
          <div className={cx(columns, gap1)}>
            <Label>Tags to delete</Label>
            <Select
              tabSelectsValue={false}
              menuIsOpen={isMenuOpen}
              onMenuClose={() => setMenuOpen(false)}
              onMenuOpen={() => setMenuOpen(true)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !isMenuOpen) {
                  e.preventDefault() //prevents form submit
                  setMenuOpen(true)
                }
                if (e.key === "Escape" && isMenuOpen) {
                  e.stopPropagation();
                }
              }}
              closeMenuOnSelect={false}
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '4px',
                  outline: state.isFocused ? '3px solid var(--blue4)' : 'none'
                }),
                control: (provided) => ({
                  ...provided,
                  borderColor: 'var(--coolGrey4)!important',
                  boxShadow: 'none'
                })
              }}
              isMulti
              noOptionsMessage={() => <>No more tags to delete.</>}
              value={tags}
              onChange={handleChange}
              // @ts-ignore
              options={options}
            />
          </div>
        </div>
        <div className={cx(rows, p3, gap2, css`justify-content: flex-end; align-items: center; gap: 8px; border-top: 1px solid var(--coolGrey3);`)}>
          <Button variant="tertiary" onClick={onClose} size="large">Cancel</Button>
          <Button type="submit" variant="secondary" onClick={onSave} size="large">Save</Button>
        </div>
      </form>
    </Tile>
  </Backdrop>
}

export default AddTagsModal