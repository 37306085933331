import fetcher from "./fetcher"
import {baseUrl} from "../index"

const auth = {
  signIn: async ({email}: { email: string }) => {
    return await fetcher(`${baseUrl}/api/auth/identify`, {
      method: 'POST',
      body: JSON.stringify({email})
    })
  },
  verify: async ({uuid}: { uuid: string }): Promise<{ data: string }> => {
    return await fetcher(`${baseUrl}/api/auth/verify`, {
      method: 'POST',
      body: JSON.stringify({uuid})
    })
  }
}

export default auth;
