import React, {ForwardedRef, HTMLAttributes, InputHTMLAttributes} from "react"
import {css, cx} from "@emotion/css"

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string
  disabled?: boolean
}

const _Input = ({className, ...rest}: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
  return <input ref={ref} className={cx(baseInputStyles, className)} {...rest}/>
}

const Input = _Input

const baseInputStyles = css`
     
  display: flex;
  width: 100%;
  height: 32px;
  padding: 0px 8px;
  
  cursor: text;
  border-radius: 4px;

  font-size: 16px;
  line-height: 24px;
  
  border: 1px solid var(--coolGrey4);
  border-radius: 4px;
  
  background: white; 
  
  ::placeholder {
    color: var(--coolGrey4);
  }
  
`

export default React.memo(React.forwardRef(Input))