import Button from "../../components/ui-kit/Button"
import React from "react"
import useModal from "../../hooks/useBoolean"
import ConfirmationModal from "./ConfirmationModal"
import useEvent from "../../hooks/useEvent"

interface DeleteBookmarksButtonProps {
  selectedBookmarkLength: number
  onDeleteBookmarks: () => void
}

const DeleteBookmarksButton = ({selectedBookmarkLength, onDeleteBookmarks}: DeleteBookmarksButtonProps) => {

  const [showDeleteConfirmationModal, onOpenDeleteConfirmationModal, onCloseDeleteConfirmationModal] = useModal()

  const onDelete = useEvent(() => {
    onOpenDeleteConfirmationModal(true)
  })

  return <>
    {showDeleteConfirmationModal && <ConfirmationModal
      body={<>
        {selectedBookmarkLength} bookmarks will be deleted if you proceed.
      </>}
      onCancel={onCloseDeleteConfirmationModal}
      onConfirm={onDeleteBookmarks}
    />}
    <Button size="large" onClick={onDelete}>Delete</Button>
  </>
}

export default DeleteBookmarksButton