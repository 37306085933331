import React from "react"
import {cx, css} from "@emotion/css"
import {gap1, p3, rows} from "../../styles/styleUtils"
import TagPlaceholder from "../../components/ui-kit/TagPlaceholder"
import BookmarkPlaceholder from "./BookmarkPlaceholder"

const BookmarksPlaceholder = () => {

  return <div>
    <div className={cx(rows, p3, gap1, css`justify-content:flex-end; align-items: center; height:57px; border-bottom: 1px solid var(--coolGrey3);`)}>
      <TagPlaceholder/>
    </div>
    <BookmarkPlaceholder/>
    <BookmarkPlaceholder/>
    <BookmarkPlaceholder/>
  </div>

}

export default React.memo(BookmarksPlaceholder)