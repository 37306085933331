import React, {ForwardedRef, HTMLAttributes} from "react"
import {cx, css} from "@emotion/css"
import {gap2, p3, rows} from "../../styles/styleUtils"
import SubTitle from "../../components/ui-kit/SubTitle"
import IconButton from "../../components/ui-kit/IconButton"
import {MdChat, MdContactSupport, MdSettings} from "react-icons/md"
import useEvent from "../../hooks/useEvent"
import SignInModal from "./SignInModal"
import Button from "../../components/ui-kit/Button"
import SavingStatus from "./SavingStatus"
import {api} from "../../App"
import Logo from "../../logo"

export interface ProductHeadingProps extends HTMLAttributes<HTMLDivElement> {
  loading: boolean
  showSignInModal: boolean
  onOpenSignInModal: () => {}
  onCloseSignInModal: () => {}
  onSettingsClick: () => void
}

const _ProductHeading = ({className, loading, showSignInModal, onOpenSignInModal, onCloseSignInModal, onSettingsClick, ...rest}: ProductHeadingProps, ref: ForwardedRef<HTMLDivElement>) => {

  const onLogout = useEvent(() => {
    localStorage.removeItem("authToken");
    api.destroy()
    window.location.replace(window.location.origin);
  })

  return <div
    ref={ref}
    className={cx(
      rows,
      p3,
      gap2,
      css`
        justify-content: space-between;
        align-items:center;
      `,
      className
    )}
    {...rest}
  >
    <div
      className={cx(rows, gap2, css`align-items: center;`)}
      {...rest}
    >
      <Logo className={css`width: 32px; height: 32px;`}/>
      <SubTitle level="l2" className={css`padding-left: 2px;`}>Keepalink</SubTitle>
      {localStorage.getItem("authToken") && <IconButton glyph={MdSettings} onClick={onSettingsClick}/>}
      <IconButton glyph={MdContactSupport} onClick={()=>{window.open("https://discord.gg/ygfwurvJD5")}}/>
      {localStorage.getItem("authToken") && <SavingStatus loading={loading}/>}
    </div>
    {showSignInModal && <SignInModal onClose={onCloseSignInModal}/>}
    {localStorage.getItem("authToken")
      ? <Button variant="tertiary" onClick={onLogout}>Log-out</Button>
      : <Button variant="tertiary" onClick={onOpenSignInModal}>Sign-in</Button>
    }
  </div>
}

const ProductHeading = _ProductHeading

export default React.memo(React.forwardRef(ProductHeading))