import Store from "../store/Store"
import * as R from "ramda"
import {ChromeExport} from "../utils/fromChromeHtmlToJson"
import patchBookmark from "../transactionCreators/patchBookmark"
import postBookmark from "../transactionCreators/postBookmark"
import postTag from "../transactionCreators/postTag"
import postBookmarkTag from "../transactionCreators/postBookmarkTag"
import {TransactionCreator} from "../api/createEditingSession"


/*
- chunking the pending transactions could be handled in editingsession, as it will need some awareness of what transaction has been confirmed within a chunk

- is it a good idea to allow this to create an array of transactions instead of a single one?
  - probably NO because it's very expensive to run the whole import multiple times on rebase
  - the alternative could be to generate "transaction creators instead" only once, apply/dispatch them and let editingsession handle the chunking
    - the drawback is that the intention of the import will be the one at the time it runned, I could mitigate this by waiting for a sync
    - I kinda like this because offline first will need to work similarly, we'd have to store the "transaction creators" to keep the "intentionality" of the user actions

- todo:
  - chromeImport will no longer be a "transaction creator" it will be a function creating an array of "transaction creators" instead
  - the list of "transaction creators" will be batched by editingsession somehow
  - thinking of offline first, it has a similar issue, we'd have to store the "transaction creators" but his aren't serializable
    - I'm a bit worried, making this serializable will turn it into something very similar to redux
      - redux has actions, and reducers, actions are serializable, reducers are functions (s)=>s
      - mine has kinda has: actions, few pre-implemented reducers a la redux, plus, transaction creators (a)=>[a], these are rebased
        ***** would be simpler if, we remove the transaction creator abstraction, and simply re-execute a reducer on rebase *****
          - ok, but I'd have to reimplement all reducers on BE as well
*/

const chromeImport = (chromeExport: ChromeExport, entities: Store["entities"]) => {

  chromeExport = R.clone(chromeExport) //the following code mutates this object

  const storeBookmarks = R.values(entities.bookmark)
  const storeTags = R.values(entities.tag)
  const storeTagsByTitle = R.indexBy(R.prop("title"), storeTags)
  const storeBookmarksByUrl = R.indexBy(R.prop("url"), storeBookmarks)
  const bookmarksTagsByTagId = R.groupBy(R.prop("tagId"), R.values(chromeExport.bookmarkTag))

  //tweaks chromeExport.bookmarksTags that have tags with the same name to point to the store tags
  for (const tag of R.values(chromeExport.tags)) {
    const storeTagWithSameTitle = storeTagsByTitle[tag.title]
    if (storeTagWithSameTitle) {
      for (const bookmarkTagToTweak of bookmarksTagsByTagId[tag.id] || []) {
        const id = bookmarkTagToTweak.bookmarkId + '_' + storeTagWithSameTitle.id
        chromeExport.bookmarkTag[id] = {
          ...bookmarkTagToTweak,
          tagId: storeTagWithSameTitle.id,
          id,
        }
        delete chromeExport.bookmarkTag[bookmarkTagToTweak.id]
      }
      delete chromeExport.tags[tag.id]
    }
  }

  const bookmarksTagsByBookmarkId = R.groupBy(R.prop("bookmarkId"), R.values(chromeExport.bookmarkTag))

  let transactionCreators: Array<TransactionCreator> = []

  //tweaks the title of store.bookmarks with the same url (import wins)
  for (const bookmark of R.values(chromeExport.bookmarks)) {
    const storeBookmarkWithSameUrl = storeBookmarksByUrl[bookmark.url]
    if (storeBookmarkWithSameUrl) {
      transactionCreators.push(patchBookmark({bookmarkId: storeBookmarkWithSameUrl.id, bookmark: {title: bookmark.title, url: bookmark.url}}))
      for (const bookmarkTagToTweak of bookmarksTagsByBookmarkId[bookmark.id] || []) {
        //tweak all related bookmark tag to link the store bookmark just tweaked
        const id = storeBookmarkWithSameUrl.id + '_' + bookmarkTagToTweak.tagId
        if (!chromeExport.bookmarkTag[id]) {
          chromeExport.bookmarkTag[id] = {
            ...bookmarkTagToTweak,
            bookmarkId: storeBookmarkWithSameUrl.id,
            id
          }
        }
        delete chromeExport.bookmarkTag[bookmarkTagToTweak.id]
      }
      delete chromeExport.bookmarks[bookmark.id]
    }
  }

  for (const tag of R.values(chromeExport.tags)) {
    transactionCreators.push(postTag({tagUuid: tag.id, tagTitle: tag.title}))
  }

  const chromeBookmarkTagsByBookmarkId = R.groupBy(R.prop("bookmarkId"), R.values(chromeExport.bookmarkTag))

  for (const bookmark of R.values(chromeExport.bookmarks)) {
    const thisBookmarkTags = chromeBookmarkTagsByBookmarkId[bookmark.id]
    const tagIdsToAdd = []
    if (thisBookmarkTags) {
      for (const bookmarkTag of thisBookmarkTags) {
        tagIdsToAdd.push(bookmarkTag.tagId)
        delete chromeExport.bookmarkTag[bookmarkTag.id]
      }
    }
    transactionCreators.push(postBookmark({title: bookmark.title, url: bookmark.url, tagsToCreate: [], tagIdsToAdd: tagIdsToAdd}))
  }

  for (const bookmarkTag of R.values(chromeExport.bookmarkTag)) {
    if (!entities.bookmarkTag[bookmarkTag.id]) {
      transactionCreators.push(postBookmarkTag({tagId:bookmarkTag.tagId, bookmarkId:bookmarkTag.bookmarkId}))
    }
  }

  return transactionCreators

}

export default chromeImport