import React, {useEffect} from 'react';
import {tabbable} from 'tabbable';

const useFocusTrap = <T extends HTMLElement>(
  ref: React.MutableRefObject<T | null>
) => {
  useEffect(() => {
    const prevFocused = document.activeElement as HTMLElement | null;

    const onFocusOut = (e: FocusEvent) => {
      if (!ref.current) {
        return;
      }
      const focusables = tabbable(ref.current);
      const firstFocusable = focusables[0];
      const lastFocusable = focusables[focusables.length - 1];
      const elementLosingFocus = e.target;
      const elementGettingFocus = e.relatedTarget;
      if (
        elementLosingFocus === firstFocusable &&
        !focusables.includes(elementGettingFocus as HTMLElement)
      ) {
        lastFocusable.focus();
      } else if (
        e.target === lastFocusable &&
        !focusables.includes(elementGettingFocus as HTMLElement)
      ) {
        firstFocusable.focus();
      }
    };

    document.addEventListener('focusout', onFocusOut);

    return () => {
      document.removeEventListener('focusout', onFocusOut);
      prevFocused?.focus();
    };
  }, [ref]);
};

export default useFocusTrap;