import React, {ElementType, HTMLAttributes} from "react"
import {css, cx} from "@emotion/css"
import {IconDefinition} from "@fortawesome/fontawesome-common-types"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

export interface IconProps extends HTMLAttributes<any> {
  glyph: ElementType | IconDefinition
  size?: 'small' | 'medium' | 'large' | 'xl' | 'xxl'
}

const isIconDefinition = (a: ElementType | IconDefinition): a is IconDefinition => {
  return (a as IconDefinition).icon !== undefined;
}

const Icon = ({children, className, glyph, size = "medium", ...rest}: IconProps) => {
  const isFaIcon = isIconDefinition(glyph)
  let C
  if (isFaIcon) {
    C = Glyph(glyph)
  } else {
    C = glyph
  }
  return <C
    className={cx(
      isFaIcon && css`padding:2.8px; box-sizing: border-box!important;`,
      css`fill: currentcolor; color: currentcolor;`,
      size === "small" && smallStyles,
      size === "medium" && mediumStyles,
      size === "large" && largeStyles,
      size === "xl" && xlStyles,
      size === "xxl" && xxlStyles,
      className
    )}
    {...rest}
  >
    {children}
  </C>
}

const Glyph = (iconDefinition: IconDefinition) => ({className}: { className: string }) => {
  return <FontAwesomeIcon icon={iconDefinition} className={className}/>
}

const smallStyles = css`
  width: 16px;
  height: 16px;
`

const mediumStyles = css`
  width: 20px;
  height: 20px;
`

const largeStyles = css`
  width: 24px;
  height: 24px;
`

const xlStyles = css`
  width: 28px;
  height: 28px;
`

const xxlStyles = css`
  width: 32px;
  height: 32px;
`

export default Icon