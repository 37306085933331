import Store from "./Store"
import {getActiveBreakpoint} from "../hooks/useResponsiveBreakpoints"

const initialState: Store = {
  entities: {
    bookmark: {},
    tag: {},
    bookmarkTag: {}
  },
  ui: {
    activeBreakpoint: getActiveBreakpoint(),
    filters: {
      title: '',
      tagIds: new Set()
    },
    unfilteredIds: new Set(),
  }
}

export default initialState
