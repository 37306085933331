import Store from "../store/Store"

const deleteBookmark = ({id}: { id: string }) => (entities: Store["entities"]) => {
  return [{
    method: 'delete' as const,
    entity: 'bookmark' as const,
    data: {
      id
    }
  }]
}

export default deleteBookmark