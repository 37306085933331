import React, {useCallback, useRef, useState} from "react"
import Tile from "../../components/ui-kit/Tile"
import {cx, css} from "@emotion/css"
import IconButton from "../../components/ui-kit/IconButton"
import Label from "../../components/ui-kit/Label"
import Backdrop from "../../components/ui-kit/Backdrop"
import {columns, gap1, gap2, gap3, p3, rows} from "../../styles/styleUtils"
import useOnEscKeydown from "../../hooks/useEscKeydown"
import SubTitle from "../../components/ui-kit/SubTitle"
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark"
import useFocusTrap from "../../hooks/useFocusTrap"
import Input from "../../components/ui-kit/Input"
import Button from "../../components/ui-kit/Button"
import useEvent from "../../hooks/useEvent"
// @ts-ignore //todo
import {useFetcher} from "react-ufo"
import auth from "../../api/auth"
import {isUrl, isValid, notEmpty, validate} from "../../utils/validation"
import Hint from "../../components/ui-kit/Hint"

interface SignInModalModalProps {
  onClose: () => void
}

const SignInModal = ({onClose}: SignInModalModalProps) => {

  useOnEscKeydown(onClose)

  const modalRef = useRef(null)
  useFocusTrap(modalRef)

  const [email, setEmail] = useState("")
  const [loginRequested, setLoginRequested] = useState(false)

  const [signIn, [signingIn]] = useFetcher(useCallback(auth.signIn, []))

  const onSignIn = useEvent(async () => {
    if (!isFormValid()) {
      setEmailTouched(true)
      return
    }
    await signIn({email})
    setLoginRequested(true)
  })

  const [emailTouched, setEmailTouched] = useState<boolean>(false)

  const validateEmail = () => {
    return validate(email, [notEmpty])
  }

  const isFormValid = () => {
    return isValid([
      validateEmail()
    ].flat())
  }

  return <Backdrop>
    <Tile ref={modalRef} className={cx(columns, css(`width:100%; max-width: 420px; max-height:100%;`))}>
      <form onSubmit={(e) => {
        e.preventDefault()
        return false
      }}>
        <div
          className={cx(rows, p3, gap2, css`justify-content: space-between; align-items: flex-start; border-bottom: 1px solid var(--coolGrey3);`)}>
          <SubTitle level="l2">
            Sign in
          </SubTitle>
          <IconButton glyph={faXmark} onClick={onClose}/>
        </div>
        <div className={cx(columns, css`flex:1; overflow: auto;`)}>
          <div className={cx(columns, p3, gap3)}>
            {!loginRequested ?
              <>
                <div>Please enter your details</div>
                <div className={cx(columns, gap1)}>
                  <Label>Email *</Label>
                  <Input autoFocus value={email} disabled={signingIn} onChange={(e) => {
                    setEmail(e.target.value)
                    setEmailTouched(true)
                  }}/>
                  {emailTouched && <Hint>{validateEmail()}</Hint>}
                </div>
              </>
              :
              <>
                Please click the link we sent to "{email}" to sign in.
              </>
            }
          </div>
        </div>
        {!loginRequested && <div
          className={cx(rows, p3, gap2, css`justify-content: flex-end; align-items:center; gap:8px; border-top: 1px solid var(--coolGrey3);`)}>
          <Button variant="tertiary" onClick={onClose} size="large">Cancel</Button>
          <Button type="submit" variant="secondary" size="large" disabled={signingIn} onClick={onSignIn}>Sign in</Button>
        </div>}
      </form>
    </Tile>
  </Backdrop>
}

export default SignInModal
