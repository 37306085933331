import React from "react"
import {css, cx} from "@emotion/css"
import {columns, gap05, gap1, gap2, gap3, p2, p3, rows} from "../../styles/styleUtils"
import IconPlaceholder from "../../components/ui-kit/IconPlaceholder"
import Placeholder from "../../components/ui-kit/Placeholder"
import InputPlaceholder from "../../components/ui-kit/InputPlaceholder"
import Label from "../../components/ui-kit/Label"
import Input from "../../components/ui-kit/Input"
import IconButton from "../../components/ui-kit/IconButton"
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons/faPenToSquare"
import TagsModal from "./TagsModal"

const FiltersPlaceholder = () => {

  return <div className={cx(columns)}>
    <div className={cx(rows, p3, gap1, css`justify-content: space-between;
        height: 57px;
        align-items: center;
        border-bottom: 1px solid var(--coolGrey3);`)}>
      <Placeholder/>
      <div className={cx(rows)}>
        <IconPlaceholder/>
        <IconPlaceholder/>
      </div>
    </div>
    <div className={cx(columns, p3, gap3)}>
      <div className={cx(columns, gap2)}>
        <Placeholder/>
        <InputPlaceholder/>
      </div>
      <div className={cx(columns, gap3)}>
        <div className={cx(rows, gap2, css`justify-content: space-between; align-items: center;`)}>
          <Placeholder/>
          <IconPlaceholder/>
        </div>
        <div className={cx(columns, gap2)}>
          {new Array(3).fill(undefined).map((o, i) => {
            return <div key={i}>
              <label className={cx(rows, gap1, css`align-items: center;
                  cursor: pointer;`)}>
                <IconPlaceholder/>
                <Placeholder/>
              </label>
            </div>
          })}
        </div>
      </div>
    </div>
  </div>

}

export default FiltersPlaceholder