import {injectGlobal} from "@emotion/css"

injectGlobal(`

* { 
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

html, body {
  color: var(--coolGrey8);
  background: var(--coolGrey1);
}

input:focus { outline: 3px solid var(--blue4); }

:focus-visible { outline: 3px solid var(--blue4); }

input[type="checkbox"]:focus-visible { outline: 3px solid var(--blue4); }

a {
  border: 0px solid transparent;
  border-radius: 4px;
}

/******* scrollbars start *******/
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--coolGrey4) transparent;
}
/* Webkit */
*::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}
*::-webkit-scrollbar-track {
  background: white;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--coolGrey4);
  border-radius: 4px;
  border: 1px solid white;
}
/******* scrollbars end *******/

`)