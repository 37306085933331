import {css, cx} from "@emotion/css"
import {gap1, p3, pb2, rows} from "../../styles/styleUtils"
import IconPlaceholder from "../../components/ui-kit/IconPlaceholder"
import Placeholder from "../../components/ui-kit/Placeholder"
import TagPlaceholder from "../../components/ui-kit/TagPlaceholder"
import Divider from "../../components/ui-kit/Divider"
import React from "react"

const BookmarkPlaceholder = ()=> {
  return <>
    <div className={cx(p3)}>
      <div className={cx(rows, gap1, pb2, css`align-items: center;`)}>
        <div>
          <IconPlaceholder/>
        </div>
        <div className={cx(css`flex: 1;`)}>
          <Placeholder/>
        </div>
        <div className={cx(rows)}>
          <IconPlaceholder/>
          <IconPlaceholder/>
        </div>
      </div>
      <div className={cx(rows, gap1, css`justify-content: flex-end;
            align-items: center;`)}>
        <TagPlaceholder/>
        <TagPlaceholder/>
      </div>
    </div>
    <Divider/>
  </>
}

export default BookmarkPlaceholder
