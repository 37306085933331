import {HTMLAttributes} from "react"
import {cx, css} from "@emotion/css"

export interface PlaceholderProps extends HTMLAttributes<HTMLDivElement> {
}

export const placeholderBaseStyles = css`height:8px; border-radius:4px; background: var(--coolGrey2);`

const Placeholder = ({className, ...rest}: PlaceholderProps) => {
  return <div className={cx(placeholderBaseStyles, css`width: 40%;`, className)} {...rest}/>
}

export default Placeholder