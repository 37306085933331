import {css} from "@emotion/css"

const rows = css`display:flex; flex-direction:row;`
const columns = css`display:flex; flex-direction:column;`

const gap05 = css`gap: 2px;`
const gap1 = css`gap: 4px;`
const gap2 = css`gap: 8px;`
const gap3 = css`gap: 12px;`
const gap4 = css`gap: 16px;`
const gap5 = css`gap: 20px;`

const p1 = css`padding: 4px;`
const p2 = css`padding: 8px;`
const p3 = css`padding: 12px;`
const p4 = css`padding: 16px;`
const p5 = css`padding: 20px;`

const pt1 = css`padding-top: 4px;`
const pt2 = css`padding-top: 8px;`
const pt3 = css`padding-top: 12px;`
const pt4 = css`padding-top: 16px;`
const pt5 = css`padding-top: 20px;`

const pb1 = css`padding-bottom: 4px;`
const pb2 = css`padding-bottom: 8px;`
const pb3 = css`padding-bottom: 12px;`
const pb4 = css`padding-bottom: 16px;`
const pb5 = css`padding-bottom: 20px;`

const px1 = css`padding-left: 4px; padding-right: 4px;`
const px2 = css`padding-left: 8px; padding-right: 8px;`
const px3 = css`padding-left: 12px; padding-right: 12px;`
const px4 = css`padding-left: 16px; padding-right: 16px;`
const px5 = css`padding-left: 20px; padding-right: 20px;`

const py1 = css`padding-top: 4px; padding-bottom: 4px;`
const py2 = css`padding-top: 8px; padding-bottom: 8px;`
const py3 = css`padding-top: 12px; padding-bottom: 12px;`
const py4 = css`padding-top: 16px; padding-bottom: 16px;`
const py5 = css`padding-top: 20px; padding-bottom: 20px;`

export {
  rows,
  columns,
  gap05,
  gap1,
  gap2,
  gap3,
  gap4,
  gap5,
  pt1,
  pt2,
  pt3,
  pt4,
  pt5,
  p1,
  p2,
  p3,
  p4,
  p5,
  pb1,
  pb2,
  pb3,
  pb4,
  pb5,
  px1,
  px2,
  px3,
  px4,
  px5,
  py1,
  py2,
  py3,
  py4,
  py5
}

export default {
  rows,
  columns,
  gap05,
  gap1,
  gap2,
  gap3,
  gap4,
  gap5,
  p1,
  p2,
  p3,
  p4,
  p5,
  pb1,
  pb2,
  pb3,
  pb4,
  pb5
}
