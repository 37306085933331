import React, {useRef, useState} from "react"
import Tile from "../../components/ui-kit/Tile"
import {cx, css} from "@emotion/css"
import IconButton from "../../components/ui-kit/IconButton"
import Label from "../../components/ui-kit/Label"
import Backdrop from "../../components/ui-kit/Backdrop"
import {columns, gap2, gap3, p3, rows} from "../../styles/styleUtils"
import useOnEscKeydown from "../../hooks/useEscKeydown"
import SubTitle from "../../components/ui-kit/SubTitle"
import ImportButton from "./ImportButton"
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark"
import BookmarkletLink from "./BookmarkletLink"
import useFocusTrap from "../../hooks/useFocusTrap"
import Button from "../../components/ui-kit/Button"

interface AddBookmarkModalProps {
  onCancel: () => void
  onConfirm: () => void
  body: React.ReactElement
}

const ConfirmationModal = ({body, onCancel, onConfirm}: AddBookmarkModalProps) => {

  useOnEscKeydown(onCancel)

  const modalRef = useRef(null)
  useFocusTrap(modalRef)

  return <Backdrop>
    <Tile ref={modalRef} className={cx(columns, css(`width:100%; max-width: 420px; max-height:100%;`))}>
      <div className={cx(rows, p3, gap2, css`justify-content: space-between; align-items: flex-start; border-bottom: 1px solid var(--coolGrey3);`)}>
        <SubTitle level="l2">
          Are you sure?
        </SubTitle>
        <IconButton glyph={faXmark} onClick={onCancel}/>
      </div>
      <div className={cx(columns, p3, css`flex: 1; overflow: auto;`)}>
        {body}
      </div>
      <div className={cx(rows, p3, gap2, css`justify-content: flex-end; align-items: center; border-top: 1px solid var(--coolGrey3);`)}>
        <Button variant="tertiary" onClick={onCancel} size="large">Cancel</Button>
        <Button type="submit" variant="secondary" size="large" onClick={onConfirm}>Delete</Button>
      </div>
    </Tile>
  </Backdrop>
}

export default ConfirmationModal
