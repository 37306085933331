import {useCallback, useLayoutEffect, useRef} from "react"

const useEvent = <T extends Function>(handler: T) => {
  const handlerRef = useRef<T | undefined>();

  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback((...args: any) => {
    const fn = handlerRef.current;
    return fn?.(...args);
  }, []);

}

export default useEvent