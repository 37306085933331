import {HTMLAttributes} from "react"
import {cx, css} from "@emotion/css"
import {placeholderBaseStyles} from "./Placeholder"

export interface IconPlaceholderProps extends HTMLAttributes<HTMLDivElement> {
}

const IconPlaceholder = ({className, ...rest}: IconPlaceholderProps) => {
  return <div className={css`padding: 4px;`}>
    <div className={cx(placeholderBaseStyles, css`width: 16px; height:16px;`, className)} {...rest}/>
  </div>
}

export default IconPlaceholder