import {HTMLAttributes} from "react"
import El from "./El"

export interface LabelProps extends HTMLAttributes<HTMLDivElement> {
}

const Label = ({children}: LabelProps) => {
  return <El
    s="
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--coolGray9);
    "
  >
    {children}
  </El>
}

export default Label