import {useEffect} from "react"

const useEscKeydown = (callback: Function) => {

  useEffect(() => {

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        callback()
      }
    };

    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };

  }, [callback]);

}

export default useEscKeydown