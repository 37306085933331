import Store from "../store/Store"
import * as R from "ramda"
import {generateKeyBetween} from "fractional-indexing"
import createUuid from "../utils/createUuid"

interface PostBookmarkTag {
  tagId: string
  bookmarkId: string
}

const postBookmarkTag = ({tagId, bookmarkId}: PostBookmarkTag) => (entities: Store["entities"]) => {

  const bookmarksTagsIndex = entities.bookmarkTag

  const result = []

  const btId = tagId + '_' + bookmarkId
  if (!bookmarksTagsIndex[btId]) {
    result.push({
      method: 'post' as const,
      entity: 'bookmarkTag' as const,
      data: {
        id: btId,
        bookmarkId,
        tagId
      }
    })
  }

  return result
}

export default postBookmarkTag;