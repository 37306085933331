import Store from "../store/Store"
import * as R from "ramda"
import {generateKeyBetween} from "fractional-indexing"

const postTag = ({tagUuid, tagTitle = 'Unnamed tag'}: { tagUuid: string, tagTitle?: string }) => (entities: Store["entities"]) => {
  const tagsIndex = entities.tag
  const tagsArray = Object.values(tagsIndex)
  const sortedTags = R.sortBy(R.prop("position"), tagsArray)
  const tag = {
    id: tagUuid,
    title: tagTitle,
    position: generateKeyBetween(null, sortedTags[0]?.position || null)
  }
  return [{
    method: 'post' as const,
    entity: 'tag' as const,
    data: tag
  }]
}

export default postTag