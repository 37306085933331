import create from 'zustand'

import initialState from "./initialState"
import Store from "./Store"
import {useZustand} from "./useZustand"

const __useStore = create<Store>((set) => initialState)

// @ts-ignore
window.getStore = () => {
  return __useStore.getState()
}

const useStore = <Slice>(selector: (state: Store) => Slice, areEqual?: (a: Slice, b: Slice) => boolean) => {
  return useZustand(__useStore, selector, areEqual);
}

useStore.subscribe = __useStore.subscribe
useStore.getState = __useStore.getState
useStore.setState = __useStore.setState

export default useStore