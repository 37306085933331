import React, {useMemo, useRef} from "react"
import IconButton from "../../components/ui-kit/IconButton"
import useStore from "../../store/useStore"
import {cx, css} from "@emotion/css"
import {columns, gap1, gap2, gap3, p3, rows} from "../../styles/styleUtils"
import areFiltersEmpty from "../../utils/areFiltersEmpty"
import Tag from "../../components/ui-kit/Tag"
import A from "../../components/ui-kit/A"
import * as R from "ramda"
import BookmarksTags from "../../store/BookmarksTags"
import memoize from "lodash.memoize"
import Icon from "../../components/ui-kit/Icon"
import {MdDelete, MdDragIndicator, MdEdit} from "react-icons/md"
import useEvent from "../../hooks/useEvent"
import useDrag from "../../hooks/useDrag"
import useDrop from "../../hooks/useDrop"
import {generateKeyBetween} from "fractional-indexing"
import {api} from "../../App"
import FavIcon from "./Favicon";
import deleteBookmark from "../../transactionCreators/deleteBookmark"
import editBookmarkPosition from "../../transactionCreators/patchBookmarkPosition"

interface BookmarkProps {
  id: string
  onEdit: (id: string) => void
  prevPosition: string | undefined
  nextPosition: string | undefined
  selectedBookmarkIds: Set<string>
  onSelectBookmark: (bookmarkId: string, selected: boolean) => void
}

const memoByBookmarkId = memoize((bookmarksTags: Record<string, BookmarksTags>) => {
  return R.groupBy(R.prop(`bookmarkId`), R.values(bookmarksTags))
})

const Bookmark = ({id, onEdit, prevPosition, nextPosition, selectedBookmarkIds, onSelectBookmark}: BookmarkProps) => {

  const bookmark = useStore((s) => s.entities.bookmark[id])

  const selectedTags = useStore((s) => {
    const tagsIndex = s.entities.tag
    const bookmarksTags = s.entities.bookmarkTag
    const bookmarksTagsByBookmarkId = memoByBookmarkId(bookmarksTags)
    return (bookmarksTagsByBookmarkId[id] || []).map(({tagId}) => tagsIndex[tagId])
  }, R.equals)

  const sortedSelectedTags = useMemo(() => R.sortBy(R.prop('position'), selectedTags), [selectedTags])

  const isUnfiltered = useStore((s) => {
    const filters = s.ui.filters
    const unfilteredIds = s.ui.unfilteredIds
    return unfilteredIds.has(id) && !areFiltersEmpty(filters)
  });

  const unfilteredStyles = css`border: 2px solid orange;`

  const onDelete = () => {
    api.applyAndDispatch(deleteBookmark({id}))
    onSelectBookmark(id, false)
  }

  const handleRef = useRef<HTMLDivElement>(null)
  const previewRef = useRef<HTMLDivElement>(null)

  const onDrop = useEvent((dragData: any, direction: any) => {
    const {id} = dragData
    if (direction === 'above') {
      api.applyAndDispatch(editBookmarkPosition({id, position: generateKeyBetween(prevPosition || null, bookmark.position || null)}))
    } else {
      api.applyAndDispatch(editBookmarkPosition({id, position: generateKeyBetween(bookmark.position || null, nextPosition || null)}))
    }
  })

  useDrag(handleRef, previewRef, {id})
  useDrop(previewRef, onDrop)

  return <>
    <div
      ref={previewRef}
      className={cx(columns, p3, gap2, css`word-break: break-all;`, isUnfiltered && unfilteredStyles)}
    >
      <div className={cx(rows, gap3, css`align-items: center;`)}>
        <div className={css`padding-top: 3px;`}>
          <span ref={handleRef}>
            <Icon className={css`cursor: grab;`} glyph={MdDragIndicator}/>
          </span>
        </div>
        <div>
          <input type="checkbox" checked={selectedBookmarkIds.has(id)} onChange={(e) => onSelectBookmark(id, e.target.checked)}/>
        </div>
        <div className={cx(css`flex: 1; align-items: flex-start;`)}>
          <A href={bookmark.url} target="_blank" className={cx(rows, gap2, css`align-items: center;`)}>
            <FavIcon url={bookmark.url}/>
            {bookmark.title || bookmark.url}
          </A>
        </div>
        <div className={cx(rows, gap1, css`align-items: flex-start;`)}>
          <IconButton onClick={() => onEdit(id)} glyph={MdEdit}/>
          <IconButton onClick={onDelete} glyph={MdDelete}/>
        </div>
      </div>
      {sortedSelectedTags.length > 0 && <div className={cx(rows, gap2, css`justify-content: flex-end; align-items: center;`)}>
        {sortedSelectedTags.map((tag) => {
          //tag is undefined, why???
          return <Tag key={tag.id}>{tag.title}</Tag>
        })}
      </div>}
    </div>
  </>

}

export default React.memo(Bookmark)
