import {MutableRefObject, useEffect} from "react"
import useEvent from "./useEvent"

interface DragContext {
  current: {
    dragData?: any
    scrollParent: HTMLElement | null
    draggedElement: HTMLElement | null
    dropTarget: Element | null
    preview: HTMLElement | null
    dragging: boolean
    direction?: 'above' | 'below'
    sourceX: number
    sourceY: number
    x: number
    y: number
  }
}

export const dragContext: DragContext = {
  current: {
    scrollParent: null,
    draggedElement: null,
    dropTarget: null,
    preview: null,
    dragging: false,
    sourceX: 0,
    sourceY: 0,
    x: 0,
    y: 0
  }
}

const useDrag = (handleRef: MutableRefObject<HTMLElement | null>, previewRef: MutableRefObject<HTMLElement | null>, dragData: any) => {

  const getDragData = useEvent(() => {
    return dragData
  })

  useEffect(() => {

    if (!handleRef.current) {
      return
    }

    const onPointerDown = (e: any) => {
      const {clientX, clientY} = e.touches ? e.touches[0] : e
      e.preventDefault() //helps with dragging over text (prevents text selection)
      if (handleRef.current) {
        let draggedElement = previewRef.current || handleRef.current
        draggedElement.style.opacity = "0"
        const rect = draggedElement.getBoundingClientRect()
        let preview = draggedElement?.cloneNode(true) as HTMLElement
        preview.style.pointerEvents = "none"
        preview.style.opacity = "0.5"
        preview.style.position = "fixed"
        preview.style.left = rect.left + "px"
        preview.style.top = rect.top + "px"
        preview.style.width = rect.width + "px"
        document.body.appendChild(preview);
        const context = dragContext.current
        context.draggedElement = draggedElement
        context.preview = preview
        context.dragData = getDragData()
        context.dragging = true
        context.sourceX = clientX
        context.sourceY = clientY
        context.scrollParent = getScrollParent(draggedElement)
      }
    }

    handleRef.current.addEventListener('touchstart', onPointerDown)
    handleRef.current.addEventListener(`mousedown`, onPointerDown)

    return () => {
      handleRef.current && handleRef.current.removeEventListener('touchstart', onPointerDown)
      handleRef.current && handleRef.current.removeEventListener(`mousedown`, onPointerDown)
    }

  }, [getDragData])

}

const getScrollParent = (node: any): any => {
  if (node == null) {
    return null;
  }
  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode);
  }
}


export default useDrag
