import React, {useEffect, useState} from 'react';
import Tile from "../../src/components/ui-kit/Tile";
import {css, cx} from "@emotion/css";
import {columns, gap3, p3} from "../../src/styles/styleUtils";
import SubTitle from "../../src/components/ui-kit/SubTitle";
import Backdrop from "../../src/components/ui-kit/Backdrop";

import useEvent from "../../src/hooks/useEvent";
import {ErrorBoundary} from "@sentry/react";

const GenericErrorHandler = ({children}: { children: React.ReactNode }) => {

  const [error, setError] = useState<any>(null);

  const onError = useEvent((e: any) => {
    if (!error) {
      setError(e);
    }
  })

  useEffect(() => {
    window.addEventListener("unhandledrejection", onError)
    return () => {
      window.removeEventListener('unhandledrejection', onError);
    };
  }, [onError])

  useEffect(() => {
    window.addEventListener('error', onError);
    return () => {
      window.removeEventListener('error', onError);
    };
  }, [onError]);

  return (
    <>
      <ErrorBoundary onError={onError}>
        {children}
      </ErrorBoundary>
      {error && (
        <Backdrop>
          <Tile className={cx(columns, css(`width:100%; max-width: 420px; max-height:100%;`))}>
            <div className={cx(columns, p3, gap3)}>
              <SubTitle level="l2">
                Ops, Something went wrong 🐛
              </SubTitle>
              <p>
                Please refresh the page and try again.
              </p>
              <p>
                Get in touch if the the problem persists.
              </p>
            </div>
          </Tile>
        </Backdrop>
      )}
    </>
  );
};

export default GenericErrorHandler;
