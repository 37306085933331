import {css, cx} from "@emotion/css"
import {ButtonHTMLAttributes, DetailedHTMLProps, HTMLAttributes, ReactNode} from "react"

export interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  left?: ReactNode
  right?: ReactNode
  variant?: 'primary' | 'secondary' | 'tertiary'
  ratio?: 'fitContent' | '1:1'
  size?: 'small' | 'medium' | 'large'
  disabled?: boolean
}

const Button = ({children, className, left, right, variant = 'primary', ratio = 'fitContent', size = 'medium', disabled=false, ...rest}: ButtonProps) => {
  return <button
    type="button"
    className={cx(
      buttonBaseStyles,
      createSizeStyles(size),
      variant === 'primary' && primaryStyles,
      variant === 'secondary' && secondaryStyles,
      variant === 'tertiary' && tertiaryStyles,
      ratio === '1:1' && css`padding:0;`,
      disabled && disabledStyles(variant === 'tertiary'),
      className
    )}
    disabled={disabled}
    {...rest}
  >
    {left}{children}{right}
  </button>
}

const buttonBaseStyles = css`
     
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 2px 8px;
  
  cursor: pointer;
  border-radius: 6px;

  font-weight: bold;
  white-space: nowrap;
  
`

const primaryStyles = css`
  color: white;
  background: var(--blue6);
  &:hover{
    background: var(--blue7);
  }
  &:active{
    background: var(--blue8);
  }
`

const secondaryStyles = css`
  color: var(--coolGrey8);
  border: 1px solid rgba(17, 24, 39, 0.85);
  background: white;
  &:hover{
    background: var(--coolGrey2);
  }
  &:active{
    background: var(--coolGrey3);
  }
`

const tertiaryStyles = cx(
  css`
    padding:0;
    color: var(--coolGrey8);
    &:hover{
      color: var(--coolGrey10);
    }
    &:active{
      color: var(--coolGrey10);
    }
  `
)

const createSizeStyles = (size: 'small' | 'medium' | 'large') => {
  if (size === `large`) {
    return css`
      height: 32px;
      font-size: 16px;
      line-height: 24px;
    `
  } else if (size === `medium`) {
    return css`
      height: 28px;
      font-size: 13.33px;
      line-height: 20px;
    `
  } else {
    return css`
      height: 24px;
      font-size: 11.11px;
      line-height: 16px;
    `
  }
}

const disabledStyles = (tertiary: boolean) => {
  return cx(
    css`
      color: var(--coolGrey5);
      cursor: not-allowed;
      &:hover{ 
        color: var(--coolGrey5);
      }
      &:active{
        color: var(--coolGrey5);
      }
    `,
    !tertiary && css`
      background: var(--coolGrey3);
      &:hover{ 
        background: var(--coolGrey3);
      }
      &:active{
        background: var(--coolGrey3);
      }
    `
  )
}

export default Button