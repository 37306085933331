import React, {useEffect, useState, useSyncExternalStore} from "react"
import {api} from "../../App"

interface SavingStatusProps {
  loading: boolean
}

const SavingStatus = ({loading}: SavingStatusProps) => {

  const pendings = useSyncExternalStore(api.subscribe, api.getSnapshot);

  const saving = pendings.size !== 0;

  useConfirmBeforeUnload(saving)

  const [allInSyncTimedOut, setAllInSyncTimedOut] = useState(true);

  const hasSavedBefore = useHasSavedBefore(saving)

  useEffect(() => {
    let timeout: number | undefined;
    const shouldDisplayAllInSync = (!saving && hasSavedBefore) || !loading
    if (shouldDisplayAllInSync) {
      setAllInSyncTimedOut(false)
      timeout = window.setTimeout(() => {
        setAllInSyncTimedOut(true)
      }, 1500)
    }
    return () => {
      shouldDisplayAllInSync && window.clearTimeout(timeout)
    }
  }, [saving, loading]);


  return <>{
    saving || loading
      ?
      "Syncing..."
      :
      allInSyncTimedOut
        ?
        ''
        :
        'All in sync'
  }</>
}


const beforeUnloadListener = (event: any) => {
  event.preventDefault();
  return (event.returnValue = "");
};

const useConfirmBeforeUnload = (saving: boolean) => {
  useEffect(() => {
    saving && window.addEventListener("beforeunload", beforeUnloadListener, {capture: true})
    return () => {
      saving && window.removeEventListener("beforeunload", beforeUnloadListener, {capture: true})
    }
  }, [saving]);
}

const useHasSavedBefore = (saving: boolean) => {

  const [hasSavedBefore, setHasSavedBefore] = useState(false)

  useEffect(() => {
    if (!hasSavedBefore && saving) {
      setHasSavedBefore(true)
    }
  }, [hasSavedBefore, saving]);

  return hasSavedBefore

}


export default SavingStatus
