import {HTMLAttributes} from "react"
import {cx, css} from "@emotion/css"

export interface HintProps extends HTMLAttributes<HTMLDivElement> {
  children: any[]
}

const Hint = ({children}: HintProps) => {
  const hasHints = children.filter(Boolean).length > 0
  return <>
    {
      hasHints && <div
        className={cx(css`
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: red;
        `)}
      >
        {children.find((d) => d !== undefined)}
      </div>
    }
  </>
}

export default Hint