import {useState} from "react"
import useEvent from "./useEvent"

const useBoolean = (defaultState: boolean = false) => {

  const [bool, setBool] = useState(defaultState)

  const setTrue = useEvent(() => {
    setBool(true)
  })

  const setFalse = useEvent(() => {
    setBool(false)
  })

  return [bool, setTrue, setFalse] as [boolean, typeof setTrue, typeof setFalse]

}

export default useBoolean