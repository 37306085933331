import React, {useRef, useState} from "react"
import Tile from "../../components/ui-kit/Tile"
import {cx, css} from "@emotion/css"
import IconButton from "../../components/ui-kit/IconButton"
import {MdClose} from "react-icons/md"
import Label from "../../components/ui-kit/Label"
import Button from "../../components/ui-kit/Button"
import Backdrop from "../../components/ui-kit/Backdrop"
import {columns, gap1, gap2, gap3, p3, rows} from "../../styles/styleUtils"
import useStore from "../../store/useStore"
import * as R from "ramda"
import createUuid from "../../utils/createUuid"
import useEvent from "../../hooks/useEvent"
import useOnEscKeydown from "../../hooks/useEscKeydown"
import CreatableSelect from 'react-select/creatable';
import SubTitle from "../../components/ui-kit/SubTitle"
import useFocusTrap from "../../hooks/useFocusTrap"
import {api} from "../../App"
import editBookmark from "../../transactionCreators/patchBookmark"
import postTag from "../../transactionCreators/postTag"

interface AddBookmarkModalProps {
  selectedBookmarkIds: Set<string>
  onClose: () => void
}

const AddTagsModal = ({selectedBookmarkIds, onClose}: AddBookmarkModalProps) => {

  const tagsIndex = useStore((s) => s.entities.tag)
  const tagsArray = R.values(tagsIndex)
  const sortedTags = R.sortBy(R.prop("position"), tagsArray)

  const [tags, setTags] = useState([]);

  const onSave = useEvent(async () => {
    // @ts-ignore
    const tagsToCreate = tags.filter((v: any) => v["__isNew__"]).map((val) => ({id: createUuid(), title: val.label}))
    const tagIdsToAdd = tags.filter((v: any) => !v["__isNew__"]).map(({value}) => tagsIndex[value].id)
    for (const tag of tagsToCreate) {
      api.applyAndDispatch(postTag({tagUuid: tag.id, tagTitle: tag.title}))
    }
    for (const bookmarkId of Array.from(selectedBookmarkIds)) {
      api.applyAndDispatch(editBookmark({bookmarkId, tagIdsToAdd: [...tagIdsToAdd, ...tagsToCreate.map((t) => t.id)]}))
    }
    onClose()
  })

  const handleChange = useEvent((
    newValue: any
  ) => {
    // @ts-ignore
    setTags(newValue)
  });

  const options = sortedTags.map((t) => ({
    value: t.id,
    label: t.title
  }))

  useOnEscKeydown(onClose)

  const [isMenuOpen, setMenuOpen] = useState(false);

  const modalRef = useRef(null)
  useFocusTrap(modalRef)

  return <Backdrop>
    <Tile ref={modalRef} className={cx(columns, css(`width:100%; max-width: 420px; max-height:100%;`))}>
      <form onSubmit={(e) => {
        e.preventDefault()
        return false
      }}>
        <div className={cx(rows, p3, gap2, css`justify-content: space-between; align-items: flex-start; border-bottom: 1px solid var(--coolGrey3);`)}>
          <SubTitle level="l2">
            Add tags
          </SubTitle>
          <IconButton glyph={MdClose} onClick={onClose}/>
        </div>
        <div className={cx(columns, p3, gap3)}>
          <div className={cx(columns, gap1)}>
            <Label>Tags to add</Label>
            <CreatableSelect
              tabSelectsValue={false}
              menuIsOpen={isMenuOpen}
              onMenuClose={() => setMenuOpen(false)}
              onMenuOpen={() => setMenuOpen(true)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !isMenuOpen) {
                  e.preventDefault() //prevents form submit
                  setMenuOpen(true)
                }
                if (e.key === "Escape" && isMenuOpen) {
                  e.stopPropagation();
                }
              }}
              closeMenuOnSelect={false}
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '4px',
                  outline: state.isFocused ? '3px solid var(--blue4)' : 'none'
                }),
                control: (provided) => ({
                  ...provided,
                  borderColor: 'var(--coolGrey4)!important',
                  boxShadow: 'none'
                })
              }}
              isMulti
              noOptionsMessage={() => <>No Tags, type to create a new one.</>}
              value={tags}
              onChange={handleChange}
              // @ts-ignore
              options={options}
            />
          </div>
        </div>
        <div className={cx(rows, p3, gap2, css`justify-content: flex-end; align-items: center; gap: 8px; border-top: 1px solid var(--coolGrey3);`)}>
          <Button variant="tertiary" onClick={onClose} size="large">Cancel</Button>
          <Button type="submit" variant="secondary" onClick={onSave} size="large">Save</Button>
        </div>
      </form>
    </Tile>
  </Backdrop>
}

export default AddTagsModal