import React, {useRef} from "react"
import Tile from "../../components/ui-kit/Tile"
import {cx, css} from "@emotion/css"
import IconButton from "../../components/ui-kit/IconButton"
import Backdrop from "../../components/ui-kit/Backdrop"
import {columns, gap2, p3, rows} from "../../styles/styleUtils"
import useOnEscKeydown from "../../hooks/useEscKeydown"
import SubTitle from "../../components/ui-kit/SubTitle"
import useFocusTrap from "../../hooks/useFocusTrap"
import {MdClose} from "react-icons/md"
import FiltersBody, {FiltersBodyProps} from "./FiltersBody"
import useFiltersHeadingActions from "./useFiltersHeadingActions"
import Button from "../../components/ui-kit/Button"

export interface FiltersModalProps extends FiltersBodyProps {
  onClose: () => void
}

const FiltersModal = ({onClose, nonBlockingFilters, setNonBlockingFilters, startFilteringTransition}: FiltersModalProps) => {

  useOnEscKeydown(onClose)

  const modalRef = useRef(null)
  useFocusTrap(modalRef)

  const {onClearFilters} = useFiltersHeadingActions({setNonBlockingFilters, startFilteringTransition})

  return <Backdrop>
    <Tile ref={modalRef} className={cx(columns, css(`width:100%; max-width: 420px; max-height:100%;`))}>
      <div
        className={cx(rows, p3, gap2, css`justify-content: space-between; align-items: flex-start; border-bottom: 1px solid var(--coolGrey3);`)}>
        <SubTitle level="l2">
          Filters
        </SubTitle>
        <IconButton glyph={MdClose} onClick={onClose}/>
      </div>
      <div className={cx(columns, css`flex:1; overflow: auto;`)}>
        <FiltersBody
          nonBlockingFilters={nonBlockingFilters}
          setNonBlockingFilters={setNonBlockingFilters}
          startFilteringTransition={startFilteringTransition}
        />
      </div>
      <div className={cx(rows, p3, gap2, css`justify-content: flex-end;`)}>
        <Button variant="secondary" onClick={onClearFilters}>Clear</Button>
        <Button onClick={onClose}>Done</Button>
      </div>
    </Tile>
  </Backdrop>
}

export default FiltersModal